@import '../../../../shared/styles/variables.scss';
.Dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ProfileData {
  display: flex;
  flex-direction: column;
}
.Advertisements {
  flex: 3;
}
.GeneralInfo {
  flex: 5;
}
.ReqtStat {
  margin: 0;
  flex: 1.8;
}

.ProfileInfo {
  flex: 2.3;
  margin: 0 0 0 24px;
}
.InfoContent {
  width: 100%;
  min-height: 148px;
  border-radius: 20px;
  padding: 20px;
  margin: 0 0 32px 0;
  background: white;
  h2 {
    font-size: 18px;
    line-height: 28px;
    // color: white !important;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    // color: white;
  }
}

.ReqInfoPreview {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 28px;
  p {
    flex: 50%;
    margin: 10px 0;
  }
  span {
    flex: 30%;
    margin: 10px 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    img {
      margin: 0 20px;
    }
  }
  button {
    width: 100px !important;
    span {
      margin: 0;
      font-size: 14px !important;
    }
  }
  button:hover {
    span {
      opacity: 0.6;
    }
  }
}

.ReqStatContainer {
  background: linear-gradient(208.23deg, #874fff 8.5%, #1c32f3 91.95%);
  border-radius: 25px;
  height: 100%;
  padding: 2px;
}

.ReqStatPaper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    margin: 7px 0;
    color: $strongBlue;
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
  }
  p {
    margin: 7px 0;
    color: $secondaryBlue;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
  }
  background-color: white;
  border-radius: 25px;
  height: 100%;
  padding: 24px;
}

.ProfileInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  min-height: 410px;
  padding: 2px;
  button {
    height: 76px;
    width: 143px;
    border-radius: 50px !important;
    span {
      font-size: 16px;
      line-height: 26px;
    }
  }
}
.ProfileButtonsContainer {
  width: 100%;
  padding: 30px 0 30px 30px;
}

.AdvertisementsContent {
  display: flex;
  flex-wrap: wrap;
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
  }
  div {
    text-align: center;
    width: 165px;
    left: 0px;
    top: 0px;
    border-radius: 25px;
    margin: 0 20px 0 0;
    img {
      height: 120px;
      width: 139px;
    }
    p {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
}
