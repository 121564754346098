.ProviderProfile {
	display: flex;
	flex-direction: column;
	// height: 100%;
	width: 100%;
	padding: 3% 3%;
}

.ProviderBackButton {
	.ProviderBackButtonCustom {
		background-color: white;
		color: black;
		border-radius: 8px;
		padding-left: 0px;
		letter-spacing: normal;
		font-size: 0.9rem;
		font-weight: 600;
		text-transform: none;
		box-shadow: none;

		&:hover {
			background-color: rgb(222, 222, 222);
		}

		&:active,
		&:focus {
			background-color: white;
		}
	}
}

.LongArrowLeft {
	padding-right: 12px;
}

// .ProviderHeader {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     width: 100%;
//     margin: 10px 0;
//     color: white;
//     background: linear-gradient(85.22deg, #0b1fcb 0%, #9747ff 92.28%);
//     border-radius: 18px;
//     padding: 0px 24px;
// }

// .ProviderHeaderText {
//     display: flex;
//     flex-direction: column;
// }

// .ProviderHeaderTextUp {
//     font-size: 0.8rem;
//     font-weight: 100;
//     padding: 24px 0px 10px 0px;
// }

// .ProviderHeaderTextDown {
//     font-size: 1.1rem;
//     padding: 10px 0px 24px 0px;
// }

// .ProviderHeaderImages {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }

// .headerLogo {
//     position: relative;
//     bottom: -20px;
//     width: 80px;
// }

// .headerAccountant {
//     position: relative;
//     bottom: -20px;
//     width: 80px;
// }

.ProviderHeaderSleek {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 10px 0;
	border-radius: 18px;
}

.ProviderHeaderTextSleek {
	display: flex;
	flex-direction: column;
}

.ProviderHeaderTextUpSleek {
	font-size: 0.8rem;
	font-weight: 500;
	color: #1c32f3;
	padding: 4px 0px 4px 0px;
}

.ProviderHeaderTextDownSleek {
	font-size: 1.1rem;
	font-weight: 600;
	padding: 6px 0px 6px 0px;
}

.DividerHeaderSleek {
	background-color: #e3e5e9;
	height: 1px;
	margin-bottom: 8px;
}

.ProviderContent {
	display: flex;
	width: 100%;
	height: 100%;
	margin: 10px 0px;

	.ProviderContentLeft {
		width: 25%;
		min-width: 200px;
		border: 1.6px solid #e3e5e9;
		border-radius: 12px;
		box-sizing: border-box;
		padding: 0px 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.ProviderMainContainer {
			display: flex;
			flex-direction: column;
			width: 100%;

			.ProviderMainContainerLeft {
				display: flex;
				justify-content: center;

				.ProfileImageContainer {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 12px 0px;
					width: 120px;

					.ProfileImage {
						width: 120px;
						height: 120px;
						border-radius: 50%;
						object-fit: cover;
						// object-position: top;
						position: relative;
					}

					.ProfileImageDefault {
						width: 120px;
						position: relative;
					}

					.Approved {
						width: 40px;
						position: absolute;
						top: 0px;
						right: 0px;
					}
				}
			}

			.ProviderProfileName {
				font-weight: 600;
				text-align: center;
			}

			.ProviderProfileAvgContainer {
				display: 'flex';
				justify-content: center;
				align-items: center;
				margin-top: 8px;

				.ProviderProfileAvgStars {
					margin: 4px 0px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.ProviderProfileAvgNumber {
					color: #505581;
					font-size: 0.8rem;
					margin-left: 6px;
				}

				.ProviderProfileReviewsNumber {
					color: #505581;
					font-size: 0.8rem;
					margin-left: 6px;
				}
			}

			.RFPButton {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 14px 0px;
				width: 100%;

				.RFPButtonCustom {
					background-color: #fdb400;
					color: black;
					border-radius: 8px;
					padding: 10px 20px;
					font-size: 0.9rem;
					font-weight: 600;
					text-transform: none;
					box-shadow: none;
					width: 100%;
					letter-spacing: normal;

					&:hover {
						background-color: #f5ca61;
					}

					&:active,
					&:focus {
						background-color: #fdb400;
					}
				}
			}

			.NotVerifiedYetConatiner {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 8px;

				.NotVerifiedYet {
					width: 16px;
				}

				.NotVerifiedYetText {
					font-size: 0.8rem;
					margin-left: 6px;
				}
			}

			.LanguagesContainer {
				display: flex;
				gap: 16px;
				margin: 8px 0px;
				flex-wrap: wrap;
				justify-content: center;

				.LangHU {
					width: 32px;
				}

				.LangGB {
					width: 32px;
				}

				.LangDE {
					width: 32px;
				}

				.LangFR {
					width: 32px;
				}

				.LangCN {
					width: 32px;
				}

				.LangRU {
					width: 32px;
				}

				.LangRO {
					width: 32px;
				}

				.LangES {
					width: 32px;
				}
			}
		}

		.DividerContentLeft {
			width: 100%;
			background-color: #e3e5e9;
			height: 1px;
			margin: 14px 0px;
		}

		.AttributesContainer {
			width: 100%;
		}

		.IndustryContainer {
			display: flex;
			align-items: center;
			width: 100%;

			.Industry {
				width: 30px;
			}

			.IndustryText {
				font-size: 0.9rem;
				font-weight: 600;
				margin-left: 8px;
			}
		}

		.IndustryContainerCards {
			display: flex;
			gap: 8px;
			flex-wrap: wrap;
			margin-top: 12px;

			.IndustryCard {
				border-radius: 4px;
				padding: 6px 6px;
				font-size: 0.9rem;
				font-weight: 500;
				color: #4d5bec;
				text-align: left;
				background-color: #f3f4fe;
			}
		}

		.RatingSumContainer {
			display: flex;
			align-items: center;
			width: 100%;

			.StarGrey {
				width: 30px;
			}

			.RatingSumAvgNumber {
				font-size: 0.9rem;
				font-weight: 600;
				margin-left: 8px;
				color: #4d5bec;
			}

			.RatingSumText {
				font-size: 0.9rem;
				color: #505581;
				margin-left: 4px;
			}
		}

		.PackageSizeContainer {
			display: flex;
			align-items: center;
			width: 100%;
			margin-top: 8px;

			.packageSize {
				width: 30px;
			}

			.PackageSizeText1 {
				font-size: 0.9rem;
				font-weight: 600;
				color: #4d5bec;
				margin-left: 8px;
			}
		}

		.DigBadgeContainer {
			display: flex;
			align-items: center;
			width: 100%;
			margin-top: 8px;

			.DigBadge {
				width: 30px;
			}

			.DigBadgeTextContainer {
				display: flex;
				flex-wrap: wrap;
				margin-left: 8px;
			}

			.DigBadgeText1 {
				font-size: 0.9rem;
				font-weight: 600;
				color: #4d5bec;
				margin-left: 4px;
			}

			.DigBadgeText2 {
				font-size: 0.9rem;
				color: #505581;
				margin-left: 4px;
			}
		}

		.WoComplaintContainer {
			display: flex;
			align-items: center;
			width: 100%;

			.WoComplaint {
				width: 30px;
			}

			.WoComplaintTextContainer {
				display: flex;
				flex-wrap: wrap;
				margin-left: 8px;
			}

			.WoComplaintNumber {
				font-size: 0.9rem;
				font-weight: 600;
				color: #4d5bec;
				margin-left: 4px;
			}

			.WoComplaintText {
				font-size: 0.9rem;
				color: #505581;
				margin-left: 4px;
			}
		}

		.NoOfCustomersContainer {
			display: flex;
			align-items: center;
			width: 100%;
			margin: 8px 0px 20px 0px;

			.NoOfCustomers {
				width: 30px;
			}

			.NoOfCustomersTextContainer {
				display: flex;
				flex-wrap: wrap;
				margin-left: 8px;
			}

			.NoOfCustomersNumber {
				font-size: 0.9rem;
				font-weight: 600;
				color: #4d5bec;
				margin-left: 4px;
			}

			.NoOfCustomersText1 {
				font-size: 0.9rem;
				color: #505581;
				margin-left: 4px;
			}

			.NoOfCustomersText2 {
				font-size: 0.9rem;
				color: #505581;
				margin-left: 4px;
			}
		}
	}

	.ProviderContentRight {
		width: 75%;
		margin-left: 24px;
		border-radius: 12px;
		box-sizing: border-box;
		height: 100%;

		.ProviderCard {
			padding: 12px 18px;
			margin-bottom: 16px;
			border: 1.6px solid #e3e5e9;
			border-radius: 12px;

			.ProviderIntroduction {
				font-weight: 600;

				.HandWave {
					padding-right: 8px;
				}
			}

			.ProviderIntroductionText {
				color: #505581;
				padding-top: 12px;
				font-size: 0.9rem;
				line-height: 1.5rem;
			}

			.ProviderLiabilityInsurance {
				font-weight: 600;

				.Shield {
					padding-right: 8px;
				}
			}

			.ProviderLiabilityInsuranceText {
				color: #505581;
				padding-top: 12px;
				font-size: 0.9rem;
				line-height: 1.5rem;
			}
		}

		.ProviderCardLast {
			padding: 12px 18px;
			border: 1.6px solid #e3e5e9;
			border-radius: 12px;

			.ReviewsPaginator {
				display: flex;
				justify-content: center;
				margin: 16px 0px;
			}

			.ProviderReviews {
				font-weight: 600;
				display: flex;
				margin-bottom: 12px;

				.SumOfReviews {
					padding-right: 8px;
					color: #4d5bec;
				}

				.StarBlue {
					padding-right: 8px;
				}
			}

			.ProviderReviewsNull {
				font-weight: 600;
				display: flex;

				.SumOfReviews {
					padding-right: 8px;
					color: #4d5bec;
				}

				.StarBlue {
					padding-right: 8px;
				}
			}

			.ProviderReviewsContainer {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 12px;
				justify-items: stretch;

				.ProviderReviewCard {
					font-size: 0.8rem;
					padding: 10px;
					border: 1.6px solid #e3e5e9;
					border-radius: 12px;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;

					.ProviderReviewHeader {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.ProviderReviewReviewer {
							font-size: 0.8rem;
							font-weight: 600;
							margin: 4px 0px;
						}

						.ProviderReviewDate {
							color: #505581;
							font-size: 0.7rem;
						}
					}

					.ProviderReviewAvgContainer {
						margin: 4px 0px;
						display: flex;
						align-items: center;

						.ProviderReviewAvgNumber {
							color: #505581;
							font-size: 0.7rem;
							margin-left: 4px;
						}
					}

					.ProviderReviewText {
						color: #505581;
					}
				}
			}
		}
	}
}

.ShowMoreButton,
.ShowLessButton {
	display: none;
}

@media only screen and (max-width: 600px) {
	.ProviderProfile {
		display: flex;
		flex-direction: column;

		.ProviderBackButton {
			padding: 6px 0px;

			.MuiButtonBase-root {
				padding: 6px 12px 6px 0px;

				&:hover {
					background-color: white;
				}

				&:active,
				&:focus {
					background-color: white;
				}
			}
		}

		.ProviderHeaderSleek {
			width: 100%;
			margin: 0px;
			border-radius: 0px;
		}

		.ProviderHeaderTextDownSleek {
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;
		}

		.ProviderHeaderTextDownSleek span {
			padding: 4px 0px;
		}

		.ProviderContent {
			display: flex;
			flex-direction: column;

			.ProviderContentLeft {
				width: 100%;
				height: 100%;

				.NoOfCustomersContainer {
					margin: 0px;
				}

				.HiddenContent {
					display: none;
				}

				.ShowMoreButton,
				.ShowLessButton {
					display: block;
					width: 100%;
					text-align: center;

					button {
						color: #4d5bec;
						font-weight: 300;
						text-decoration: underline;
						letter-spacing: normal;
						padding: 10px 20px;
						font-size: 0.9rem;
						text-transform: none;
						box-shadow: none;
					}
				}

				&.show .HiddenContent {
					display: block;
				}

				&.show .ShowMoreButton {
					display: none;
				}

				&.show .ShowLessButton {
					display: block;
				}
			}

			.ProviderContentRight {
				width: 100%;
				margin: 16px 0px 0px 0px;

				.ProviderCardLast {
					margin-bottom: 80px;

					.ProviderReviewsContainer {
						display: grid;
						grid-template-columns: repeat(1, 1fr);
						gap: 12px;
						justify-items: stretch;
					}

					.ReviewsPaginator {
						display: flex;
						justify-content: center;
						margin: 16px 0px;

						.MuiPaginationItem-root {
							color: transparent;
							background-color: #d2d6fa;
							border-radius: 50%;
							width: 10px;
							height: 10px;
							margin: 0 5px;
							padding: 0;
							min-width: auto;
						}

						.MuiPaginationItem-root.Mui-selected {
							background-color: #4d5bec;
						}

						.MuiPaginationItem-previousNext {
							display: none;
						}
					}
				}
			}

			.ProviderMainContainer {
				display: flex;
				flex-direction: row;
				// align-items: center;
				align-items: flex-start;

				// .ProviderMainContainerLeft {}

				.ProviderMainContainerRight {
					padding: 16px 0px 16px 16px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.ProviderProfileName {
						text-align: left;
					}

					.ProviderProfileAvgContainer {
						display: flex;
						margin: 0px 0px;
					}

					.LanguagesContainer {
						display: flex;
						justify-content: flex-start;
					}
				}

				.RFPButton {
					position: fixed;
					display: flex;
					bottom: 0px;
					left: 0px;
					padding: 16px;
					width: 100%;
					background-color: white;
					border-top: 1.6px solid #e3e5e9;
					z-index: 100;

					.RFPButtonCustom {
						&:hover {
							background-color: #fdb400;
						}

						&:active,
						&:focus {
							background-color: #fdb400;
						}
					}
				}
			}
		}
	}
}
