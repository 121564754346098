@import '../../shared/styles/variables.scss';
.paper-container-review {
  background: #fff;
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
}
.review-title {
  color: $sprayBlue;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}
.review-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.5em;
  border-bottom: 0.1rem solid rgba(51, 97, 152, 0.1);
  margin-bottom: 1em;
}
.review-provider-name {
  color: $catalinaBlue;
  font-size: 1.5em;
  font-weight: 500;
  display: flex;
}
.review-provider-info {
  color: $catalinaBlue;
  font-size: 0.8em;
  margin-top: 1em;
  margin-left: 0.5em;
}
.review-provider-icon {
  margin-right: 0.18em;
  margin-bottom: -0.3em;
}
.review-provider-avatar {
  margin: 0.5em;
  height: 180px !important;
  width: 180px !important;
  border: 0.2rem solid rgba(214, 214, 214, 0.2);
}
.review-provider-services {
  color: $azureBlue;
  font-size: 1em;
  padding: 0.4em;
  margin: 0.3em !important;
  background-color: white;
  text-align: center;
  border-radius: 0.2em;
  border: 1px solid $azureBlue;
}
.review-paragraph {
  color: $catalinaBlue;
  text-align: justify;
  font-size: 0.8em;
}
.no-review-text {
  text-align: center;
}

.review-summary-container {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0.3em;
  height: 7.5em;
}
.review-summary-progressbar-container {
  background-color: $sprayBlue;
  padding: 0.5em;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: right;
  height: 100%;
  width: 100%;
}
.review-summary-text-container {
  background-color: $azureBlue;
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
}
.review-summary-text-avg {
  font-size: 2.3em;
  text-align: center;
  color: white;
  margin: 0;
}
.review-summary-text-all {
  font-size: 1em;
  text-align: center;
  color: white;
  margin: 0;
}
.description-container {
  margin-left: 0.3em;
}
.select-button {
  text-transform: none !important;
}
.progress-bar-prov-summary {
  width: 10em;
  border-radius: 0.3em;
  height: 0.5em;
  align-self: center;
  margin-left: 1em;
  margin-right: 1em;
}

// .providerPaper {
//   background: #f0f2fe !important;
//   padding: 40px 40px 24px !important;
//   border-radius: 8px;
//   width: 450px;
//   box-shadow: 0px 27px 7px rgba(28, 50, 243, 0.01),
//     0px 17px 7px rgba(28, 50, 243, 0.04), 0px 10px 6px rgba(28, 50, 243, 0.15),
//     0px 4px 4px rgba(28, 50, 243, 0.26), 0px 1px 2px rgba(28, 50, 243, 0.29),
//     0px 0px 0px rgba(28, 50, 243, 0.3);
// }

.providerLoginPaper {
  background: #f0f2fe !important;
  padding: 40px 40px 0 !important;
  border-radius: 8px;
  width: 450px;
  height: 500px;
  box-shadow: 0px 27px 7px rgba(28, 50, 243, 0.01),
    0px 17px 7px rgba(28, 50, 243, 0.04), 0px 10px 6px rgba(28, 50, 243, 0.15),
    0px 4px 4px rgba(28, 50, 243, 0.26), 0px 1px 2px rgba(28, 50, 243, 0.29),
    0px 0px 0px rgba(28, 50, 243, 0.3);
}

@media screen and (max-width: 800px) {
  .progress-bar-prov-summary {
    width: 7em;
  }
  .progressbar-average-text {
    font-size: 0.8em;
  }
  .review-provider-name {
    font-size: 1em;
  }
  .select-button {
    font-size: 0.7em !important;
    padding: 0 !important;
  }
  .providerLoginPaper {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 550px) {
  .review-summary-container {
    height: 5.5em;
  }
  .progress-bar-prov-summary {
    width: 7em;
  }
  .paper-container-review {
    flex-direction: column;
  }
  .progressbar-average-text {
    font-size: 0.7em;
    margin-bottom: 0.2em;
    margin-right: -0.1em;
  }
}

@media screen and (max-width: 360px) {
  .review-provider-info {
    font-size: 0.65em;
  }
}
@media screen and (max-width: 340px) {
  .review-provider-info {
    font-size: 0.55em;
  }
}
@media screen and (max-width: 335px) {
  .review-provider-info {
    font-size: 0.55em;
  }
}
