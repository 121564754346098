@import '../../../../shared/styles/variables.scss';

.dlpProviderCardPaper {
	display: flex;
	flex-direction: column;
	// min-height: 225px;
	height: 100%;
	width: 100%;
	// min-width: 380px;
	border: 1px solid #e3e5e9;
	border-radius: 6px !important;
	padding: 12px !important;

	cursor: pointer;
}

.dlpProviderCardBottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	//height: 100%;
	margin-top: auto;
	margin-bottom: 0;
}


.dlpProviderCardBottomSub {
	margin-right: 4px;
}

.dlpProviderCardPaper:hover {
	background-color: rgba(55, 151, 240, 0.1) !important;
	//height: 241px !important;
}

.dlpProviderCardSection {
	display: flex;
	flex-direction: column;

	p {
		margin: 0px;
		font-weight: 500;
		line-height: 20px;
		color: #505581;
	}
}

.dlpProviderCardHeader {
	width: 100%;
	position: relative;
	// height: 65px;
	height: auto;
	display: flex;
	gap: 8px;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 8px;
}

.dlpProvHeaderTexts {
	max-width: 100%;
	flex-grow: 1;

	p {
		font-size: 16px;
	}

	span {
		font-size: 16px;
	}
}

.dlpbadgecontainer {
	height: 24px;
	background: rgba(28, 50, 243, 0.05);
	padding: 4px 6px 4px 6px;
	border-radius: 4px;
	font-family: 'Satoshi', sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	text-align: left;
}

.dlpProviderCardTextContainer {
	width: 100%;
}

.dlpProviderCardRating {
	display: flex;
	align-items: center;
	padding: 2px 0;
}

.dlpApprovedProvCard {
	width: 16px;
}

.dlpApprovedProvCardImg {
	width: 10px;
}

.dlpApprovedProv {
	// position: absolute;
	// top: 0;
	// right: 0;
	display: flex;
	align-items: center;
}

.dlpApprovedText {
	// width: 60px;
	width: 100%;
	margin-left: 3px;
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	color: #14bf96;
}

.dlpScoreStyle {
	color: $babyBlue;
	min-height: 1.6rem;
}

.dlpContactInfo {
	color: $strongBlue;
	font-weight: 500;
}

.dlpProviderCardScores {
	display: flex;
	flex-direction: column;

	p {
		display: flex;
		justify-content: space-between;

		span {
			font-family: 'Satoshi', sans-serif;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			text-align: left;
			color: #0b0f31;
		}
	}
}

.dlpClosingX {
	cursor: pointer;
}

.dlpExpContainer {
	// max-height: 56px;
	max-height: auto;
	// margin-top: 12px;
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}

.dlpExpItem {
	border-radius: 4px;
	padding: 4px 6px;
	background-color: #1c32f30d;
	width: fit-content;
	color: #1c32f3;

	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
}

.fullyOnline {
	width: 10px;
}

.digitalAccountant {
	width: 10px;
}

.dlpText12 {
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	color: #505581;
}

.dlpTextProvSize {
	font-size: 12px;
	font-weight: 500;
	line-height: 24px;
	color: #505581;
}

.dlpText14 {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	color: #505581;
}

.dlpBold20 {
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	color: #0b0f31;
}

.dlpBold16 {
	font-family: 'Satoshi', sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: #0b0f31;
}

.dlpBold14 {
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	color: #0b0f31;
}

.dlpForwardDialog {
	width: 420px;
	// height: 420px;
	height: auto;
	border-radius: 12px;
}

.dlpForwardHeader {
	display: flex;
	align-items: center;
}

.dlpForwardContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 18px;
}

.dlpBtnFooter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
}

.dlpDividerProviderCard {
	display: none;
}

@media (max-width: 600px) {
	.dlpProviderCardPaper {
		flex-direction: column;
		// max-width: 422px;
		// height: 278px;
		// min-width: 380px;
		min-width: 100%;
	}

	.dlpProviderCardSection {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		p {
			margin: 8px 0px;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
		}
	}

	.dlpProviderCardSectionContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 4px;

		p {
			line-height: 16px;
			font-size: 14px;
		}
	}

	.dlpProviderCardHeader {
		width: 100%;
	}

	.dlpProviderCardScores {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		p {
			margin: 0;
		}
	}

	.dlpScoreStyle {
		font-weight: bold !important;
		color: $strongBlue;
		font-size: 14px !important;
	}

	.dlpProvHeaderTexts {
		p {
			font-size: 14px;
		}

		span {
			font-size: 14px;
		}
	}

	.dlpProviderCardBottom {
		margin: 0px;
	}

	.dlpForwardDialog {
		width: 100%;
		height: 100%;
		border-radius: 0px;
	}

	.dlpForwardHeader {
		width: 100%;
		height: 100%;
		border-radius: 0px;
	}

	.dlpForwardDialogPaper {
		width: 100%;
		margin: 0px;
		position: relative;
		border-radius: 0px !important;
	}

	.MuiDialog-container.MuiDialog-scrollPaper {
		display: flex;
		align-items: flex-end;
	}

	.dlpDividerProviderCard {
		display: block;
		width: 100%;
		background-color: #E3E5E9;
		height: 1px;
		margin-top: 18px;
	}
}