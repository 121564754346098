@import '../../shared/styles/variables.scss';

.provDataCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ProviderDataContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 820px;
  width: 1200px;
  margin: 0px 70px 70px 0px;
}

.ProviderDataHelp {
  /*   position: sticky; */
  top: 0;
  display: flex;
  padding: 0;
  /*   align-items: center; */
  min-height: 820px;
  flex: 2;
}

.ProviderDataContent {
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  // min-height: 820px;
  height: 100%;
  flex: 3;
}

.providerPaper {
  background: #f0f2fe !important;
  margin-top: 40px;
  padding: 40px 40px 24px !important;
  border-radius: 8px;
  width: 1200px;
  box-shadow: 0px 27px 7px rgba(28, 50, 243, 0.01),
    0px 17px 7px rgba(28, 50, 243, 0.04), 0px 10px 6px rgba(28, 50, 243, 0.15),
    0px 4px 4px rgba(28, 50, 243, 0.26), 0px 1px 2px rgba(28, 50, 243, 0.29),
    0px 0px 0px rgba(28, 50, 243, 0.3);
}

/* .ProviderDataHelpContent {
  position: sticky;
  top: 0;
} */
.PricingHelp {
  p {
    margin: 4px 0;
  }
}
.tsparticles-canvas-el {
  height: 100% !important;
  aspect-ratio: 1 / 1 !important;
}
.ProviderDataHelpBox {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 30px 36px;
  min-height: 820px;
  width: 482px;
  background: linear-gradient(208.23deg, #874fff 8.5%, #1c32f3 91.95%);
  border-radius: 20px;
  color: white;
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  h2 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    color: white !important;
  }
  h3 {
    color: white !important;
  }
  ul {
    list-style-type: none;
    padding-left: 20px;
    li {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
    }
  }
  ul li:before {
    content: '-';
    position: absolute;
    margin-left: -20px;
  }
}

.StepperContainer {
  width: 100%;
  h2 {
    color: $babyBlue;
    font-size: 36px;
    font-weight: 600;
    line-height: 46px;
  }
}

.ProviderDataStepContent {
  display: flex;
  flex-direction: column;
  min-height: 580px;
}

.ProviderNavigationContainer {
  //position: absolute;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  width: 1200px;
  //bottom: 0;
  z-index: 100;
}

.CenteredNav {
  justify-content: flex-end !important;
}

.ProviderInputTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  width: 100%;
  text-align: left;
  font-family: 'Satoshi', sans-serif;
  color: #1c32f3 !important;
  span {
    font-size: 20px;
  }
}
.ProviderInputSubTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  text-align: left;
  margin-top: 0;
}

.ProviderServicesContainer {
  display: flex;
  flex-wrap: wrap;
  div {
    flex: 50%;
  }
}

.ProviderInputContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // background: #fff9f0;
  padding: 32px;
  border-radius: 20px;
  div {
    flex: 100% 1 2;
    margin: 6px 0px;
  }
}

.ZipInput {
  flex: 30% !important;
  margin-right: 20px !important;
}

.CityInput {
  flex: 60% !important;
}
.ProviderInputContainerCentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
}
.RestrictionTitle {
  font-weight: 600;
  font-size: 18px;
  width: 69%;
  text-align: left;
  margin: 12px 0;
}
.CenteredInput {
  display: flex;
  align-items: center;
  line-height: 24px;
  p {
    flex: 60%;
    font-size: 18px;
    margin: 12px 0 12px 0;
  }
  span {
    font-size: 12px;
  }
}
.RestrictionsNotification {
  border: 2px solid $secondaryBlue;
  border-radius: 20px;
  //height: 120px;
  width: 100%;
  padding: 12px;
  margin: 6px 0;
  background: white;
  h2 {
    color: $secondaryBlue;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
  p {
    color: $secondaryBlue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
}
.RestrictionInputAll {
  display: flex;
  align-items: center;
  line-height: 24px;
  width: 100%;
  border: 1px solid #2d46b9;
  padding: 10px;
  border-radius: 12px;
  background: white;
  p {
    flex: 60%;
    font-size: 18px;
    margin: 0 0;
    font-weight: 600;
  }
  span {
    font-size: 12px;
  }
}
.PreferencesInputAll {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  line-height: 24px;
  width: 100%;
  border: 1px solid #2d46b9;
  padding: 10px;
  border-radius: 12px;
  background: white;
  p {
    flex: 60%;
    font-size: 18px;
    margin: 0 0;
    font-weight: 600;
  }
  span {
    font-size: 12px;
  }
}

.RestrictionRangeContainer {
  // background: #fff9f0;
  border-radius: 20px;
  padding: 24px;
  width: 100%;
}

.RangeInputContainer {
  display: flex;
  justify-content: space-around;
  p {
    flex-basis: 60%;
  }
}

.RangeInput {
  margin: 10px;
}

.ExperienceInputRatings {
  display: flex;
  width: 98%;
  justify-content: flex-end;
  p {
    width: 42px;
    font-size: 12px;
    line-height: 22px;
  }
}
.ExpScoreBoard {
  display: flex;
  justify-content: space-between;
  height: 112px;
  border-radius: 20px;
  padding: 14px;
  // background: #fff9f0;
  box-shadow: 0px 4px 12px rgba(11, 63, 246, 0.4);
  margin-bottom: 48px;
  h2 {
    color: black;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
    margin: 0;
  }
  p {
    color: $secondaryBlue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
    margin: 0;
  }
}

.PaddingBot100 {
  padding-bottom: 100px;
}

.Description {
  padding: 4px 16px !important;
  width: 100%;
}
.AdornmentInputContainer {
  background-color: white;
  max-height: fit-content;
  div {
    margin: 0 !important;
    flex: none;
  }
  img {
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
  }
}

.Price {
  width: 48%;
  margin: 12px 0;
}

.ProviderPricingContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // background: #fff9f0;
  padding: 32px;
  border-radius: 20px;
  margin: 0;
}

.PriceInputContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  // background: #fff9f0;
  border-radius: 20px;
  p {
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    margin: 12px 0;
  }
}
.PricingWizardContainer {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  border: 2px solid $secondaryBlue;
  border-radius: 20px;
  padding: 24px;
  margin-top: 20px;
  background: white;
  h2 {
    color: $secondaryBlue;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: $secondaryBlue;
    margin: 0;
  }
}
.PricingWizardText {
  flex-basis: 55%;
}

.PricingWizardSelector {
  flex-basis: 40%;
  p {
    margin: 0;
  }
}

.WizardIcon {
  position: absolute;
  right: 2%;
  top: 5%;
  cursor: pointer;
}

.ProfileImageInput {
  display: flex;
  flex-wrap: wrap;
  // background: #fff9f0;
  padding: 32px;
  border-radius: 20px;
  width: 100%;
  div {
    flex: 1;
    margin: 5px 0px;
  }
}
.ProfileImage {
  position: relative;
}

.UploadIcon {
  cursor: pointer;
  position: absolute;
  bottom: 6.5%;
  right: 5%;
}

.PricingError {
  color: red;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
.PackageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PackageContent {
  display: flex;
  width: 100%;
  height: 90%;
  padding: 10px 0 40px 0;
}
.PackageHeader {
  height: 60px;
  margin: 0 0;
  display: flex;
  width: 100%;
  height: 90%;
  padding: 40px 24px 0 24px;
}
.PackageHeaderLeft {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 2;
  width: 100%;
  height: 82px;
  padding: 0;
  margin: 0 24px;
  p {
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    margin: 0;
  }
}
.PackageHeaderRight {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 4;
  width: 100%;

  padding: 0;
  margin: 0 24px;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    text-align: center;
    color: $secondaryBlue;
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    margin: 0;
    span {
      font-size: 16px;
      color: #303030;
    }
  }
}
.PackageProperties {
  background: #1c32f3;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%;
  height: 100%;
  padding: 24px;
}
.PackageList {
  // background: #fff9f0;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  flex-direction: column;
  flex: 4;
  width: 100%;
  height: 100%;
  padding: 24px;
}
.PackageRow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    color: white;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
}
.PackageButtonContainer {
  height: 250px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    height: 158px;
    width: 176px;
    background: white;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(11, 63, 246, 0.4);
    cursor: pointer;
    p {
      color: $secondaryBlue;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin: 0;
      span {
        font-weight: 400;
        color: black;
      }
    }
  }
}
.PaymentIntervalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //width: 736px;
  //height: 248px;
  // background: #fff9f0;
  box-shadow: 0px 4px 12px rgba(11, 63, 246, 0.4);
  border-radius: 20px;
  padding: 0 36px 36px;
  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0px;
    text-align: left;
    color: black;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    span {
      color: $secondaryBlue;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
.PropertiesBorder {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.ListBorder {
  border-bottom: 1px solid rgba(55, 151, 240, 0.2);
}
.PackageSummary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
  h2 {
    flex-basis: 100%;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
  }
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: $secondaryBlue;
    margin-bottom: 0;
  }
  div:nth-child(2) {
    flex-basis: 35%;
    border-radius: 20px;
    // background: #fff9f0;
    height: 186px;
    padding: 24px;
    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
    }
    span {
      font-family: 'Satoshi', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      opacity: 0.8;
    }
  }
  div:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 60%;
    border-radius: 20px;
    border: 2px solid $secondaryBlue;
    background: white;
    h3 {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: black;
      margin: 0;
    }
    p {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      color: $secondaryBlue;
      margin: 6px 0;
      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}

.PriceToPayContainer {
  // background: #fff9f0;
  box-shadow: 0px 4px 12px rgba(11, 63, 246, 0.4);
  background: white;
  width: 300px;
  border-radius: 20px;
  margin-top: 20px;
  color: $secondaryBlue;
  text-align: center;
  padding-bottom: 10px;
  p:nth-child(1) {
    font-size: 18px;
    font-weight: bold;
  }
  p {
    margin-bottom: 2px;
  }
  b {
    font-size: 18px;
    font-weight: bold;
  }
}

.PaymentMethod {
  width: 60%;
  h2 {
    margin: 40px 0;
    flex-basis: 100%;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #303030;
  }
}

.PaymentSelector {
  height: 86px;
  border-radius: 20px;
  padding: 24px;
  // background: #fff9f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PartnerCodeInput {
  border-radius: 20px;
  padding: 24px;
}
.Mkoepopup {
  height: 418px;
  width: 385px;
  left: 528px;
  top: 306px;
  border-radius: 20px;
  padding: 32px;
  background-color: white;
  text-align: center;
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    color: $secondaryBlue;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.15000000596046448px;
  }
  b {
    color: $secondaryBlue;
  }
}
.modifierInput {
  display: flex;
  justify-content: flex-end;
}

.incrBtn {
  padding: 10px;
  background-color: #fdb400;
  color: #111e92;
  border: none;
  margin: 0 12px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.incrBtn:hover {
  background-color: #fdd500;
}
.decrBtn {
  padding: 10px;
  background-color: #2d46b9;
  color: #f0f2fe;
  border: none;
  margin: 0 12px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.decrBtn:hover {
  background-color: #f0f2fe;
  color: #303030;
}
.disBtn {
  padding: 10px;
  background-color: lightgrey;
  color: #303030;
  border: 1px solid #303030;
  margin: 0 12px;
}

.incrInput {
  padding: 0 10px;
  width: 50px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #303030;
}
.incrBtn:hover {
  background-color: #fdd500;
}
.incrInput2 {
  padding: 0 10px;
  width: 150px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #303030;
}
.incrBtn:hover {
  background-color: #fdd500;
}

.modHint {
  width: 45%;
  text-align: right;
  color: #2d46b9;
  font-size: 16px;
  line-height: 22px;
  margin-left: 50px;
  font-weight: 600;
}

@media (max-width: 600px) {
  .Mkoepopup {
    width: auto;
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
  .ProviderDataHelp {
    display: none;
  }
  .ProviderDataContent {
    width: 100%;
    padding: 0 10px 0 10px;
  }
  .PaddingBot100 {
    width: 100%;
  }
  .ProviderDataContainer {
    margin: 0px;
    // min-height: 820px;
    height: 100%;
    // width: 1200px;
    width: 100%;
  }
  .ProviderDataStepContent {
    min-height: 100%;
  }
  .PaymentMethod {
    width: 100%;
    h2 {
      margin: 18px 0;
    }
  }
  .ProviderNavigationContainer {
    width: 100%;
    z-index: 100;
  }
  .ProviderInputContainer {
    width: 100%;
    padding: 12px 12px !important;
  }
  .RestrictionRangeContainer {
    width: 100%;
    padding: 12px;
  }
  .StepperContainer {
    h2 {
      font-size: 26px;
    }
  }
  .ProviderInputTitle {
    font-size: 22px;
    line-height: 22px;
  }
  .ProviderInputSubTitle {
    font-size: 16px;
    line-height: 22px;
  }
  .RestrictionsNotification {
    h2 {
      font-size: 18px;
    }
    height: 190px;
  }
  .RestrictionTitle {
    width: 100%;
    text-align: center;
  }
  .RestrictionInputAll {
    width: 100%;
    padding: 0 10px 0 10px;
    p {
      font-size: 16px;
    }
    span {
      font-size: 10px;
    }
  }
  .PreferencesInputAll {
    width: 100%;
    padding: 0 10px 0 10px;
    p {
      font-size: 16px;
    }
    span {
      font-size: 10px;
    }
  }
  .CenteredInput {
    p {
      font-size: 14px;
      flex-basis: 50%;
    }
    span {
      font-size: 10px;
    }
  }
  .RangeInputContainer p {
    font-size: 14px;
  }
  .ExpScoreBoard {
    p {
      font-size: 12px;
      line-height: 18px;
    }
    h2 {
      font-size: 18px;
    }
  }
  .InputColumn {
    flex-wrap: wrap;
    p {
      flex-basis: 100%;
      margin-left: 14px;
    }
  }
  .ExperienceInputRatings {
    width: 100%;
    justify-content: flex-start;
    padding: 0 32px;
    p {
      width: 54px;
    }
  }
  .Width60 {
    width: 100% !important;
  }
  .PricingWizardContainer {
    flex-direction: column;
    h2 {
      font-size: 20px;
    }
  }
  .Price {
    width: 100%;
  }
  .PackageProperties {
    padding: 24px 6px;
    width: 30%;
    div {
      width: 80px;
    }
  }
  .PropertiesBorder {
    width: 100% !important;
  }
  .PackageRow {
    height: 80px;
    margin: 0;
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .PackageHeader {
    padding: 40px 0 0 0;
    p {
      font-size: 18px;
    }
  }
  .PackageHeaderRight {
    margin: 0 12px;
    p {
      font-size: 14px;
      line-height: 22px;
    }
    span {
      font-size: 12px !important;
    }
  }
  .PackageHeaderLeft {
    margin: 0 0 0 8px;
  }
  .PackageList {
    max-width: 250px;
    padding: 24px 0;
    width: 70%;
  }
  .PackageButtonContainer {
    margin: 0;
    div {
      box-shadow: none;
      background-color: transparent;
      justify-content: flex-start;
      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .PaymentIntervalContainer {
    max-width: 375px;
  }
  // .CenteredNav {
  //   left: 0%;
  // }
  .switchSlider:before {
    width: 65px !important;
    height: 40px !important;
  }
  .PackageSummary {
    flex-direction: column;
    div {
      margin: 12px 0;
      padding: 12px;
    }
  }
  .PaymentSelector {
    flex-direction: column;
    height: fit-content;
    align-items: flex-start;
  }
  .providerPaper {
    background: #f0f2fe !important;
    margin-top: 40px;
    padding: 20px 10px 24px !important;
    border-radius: 8px;
    width: 100%;
  }
  .incrBtn {
    padding: 10px;
    background-color: #fdb400;
    color: #111e92;
    border: none;
    margin: 0 12px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

@media (min-width: 601px) and (max-width: 1000px) {
  .providerPaper {
    background: #f0f2fe !important;
    margin-top: 40px;
    padding: 40px 40px 24px !important;
    border-radius: 8px;
    width: 650px;
  }
  .PackageButtonContainer {
    margin: 0 16px;
    div {
      display: flex;
      flex-direction: column;
      height: 120px;
      width: 100px;
      cursor: pointer;
    }
  }
  .ProviderNavigationContainer {
    width: 650px;
  }
}
@media (min-width: 1001px) and (max-width: 1190px) {
  .providerPaper {
    background: #f0f2fe !important;
    margin-top: 40px;
    padding: 40px 40px 24px !important;
    border-radius: 8px;
    width: 900px;
  }
  .PackageButtonContainer {
    margin: 0 16px;
    div {
      display: flex;
      flex-direction: column;
      height: 158px;
      width: 120px;
      cursor: pointer;
    }
  }
  .ProviderNavigationContainer {
    width: 900px;
  }
}
@media (min-width: 1191px) and (max-width: 1300px) {
  .providerPaper {
    background: #f0f2fe !important;
    margin-top: 40px;
    padding: 40px 40px 24px !important;
    border-radius: 8px;
    width: 1000px;
  }
  .PackageButtonContainer {
    margin: 0 16px;
    div {
      display: flex;
      flex-direction: column;
      height: 158px;
      width: 120px;
      cursor: pointer;
    }
  }
  .ProviderNavigationContainer {
    width: 1000px;
  }
}
