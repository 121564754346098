@import "../../../../shared/styles/variables.scss";
.RequestsContainer {
  width: 100%;
  display: flex;
}
.RequestListContainer {
  flex: 5;
}
.ReqListPaper {
  border-radius: 12px !important;
  padding: 4px 18px;
}
.ReqListItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 28px;
  color: $secondaryBlue;
  p {
    flex: 40%;
    margin: 10px 0;
  }
  span {
    flex: 15%;
    margin: 10px 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    img {
      margin: 0 10px;
    }
  }
  button {
    margin: 0 0 6px 0 !important;
    width: 100px !important;
    span {
      margin: 0;
    }
  }
  button:hover {
    span {
      opacity: 0.6;
    }
  }
  div {
    flex: 15%;
  }
}

.ReqDialogContainer {
  position: relative;
  width: 500px;
  background-color: white;
  padding: 24px;
}
.ReqDialogClose {
  position: absolute;
  right: 1.5%;
  top: 1%;
  cursor: pointer;
}
.ReqDataContainer {
  display: flex;
  flex-wrap: wrap;
}

.ReqDataLabel {
  flex-basis: 50%;
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    margin: 3px 0;
  }
}

.ReqDataValue {
  flex-basis: 50%;
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    color: $secondaryBlue;
    margin: 0;
    width: 100%;
  }
}

.CompanyPhoneContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  flex-basis: 100%;
  border-radius: 20px;
  border: 2px solid $secondaryBlue;
  line-height: 20px;
  p:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
    margin: 6px 0;
    flex-basis: 55%;
  }
  p:nth-child(2) {
    font-size: 14px;
    font-weight: 600;
    margin: 6px 0;
    color: $secondaryBlue;
    flex-basis: 45%;
  }
  p:nth-child(3) {
    font-size: 14px;
    font-weight: 400;
    margin: 6px 0;
    flex-basis: 100%;
  }
}

.ReqDataPriceContainer {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff9f0;
  border-radius: 20px;
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    margin: 6px 0;
    flex-basis: 45%;
    color: $secondaryBlue;
  }
}

.ProvPriceInput {
  display: flex;
  align-items: center;
  width: 40%;
}
.ReqButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  h2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin: 6px 0;
    flex-basis: 100%;
    color: black;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    flex-basis: 100%;
    margin: 6px 0;
  }
}

.ReqButtons {
  display: flex;
  position: relative;
  div {
    flex-basis: 30%;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 4px 12px rgba(11, 63, 246, 0.4);
    border-radius: 20px;
    padding: 6px;
    text-align: center;
    margin: 0 6px;
    h2 {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0px;
      margin: 0;
      flex-basis: 100%;
      color: black;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      flex-basis: 100%;
      min-height: 80px;
      margin: 0;
    }
    p:nth-child(4) {
      position: relative;
      font-size: 14px;
      font-weight: 600;
      line-height: 26px;
      flex-basis: 100%;
      color: $secondaryBlue;
      min-height: 20px;
    }
  }
}
.PriceCompare {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px 0 0 0;
  div {
    text-align: center;
    flex-basis: 30%;
    background: #fff9f0;
    border-radius: 20px;
    padding: 12px;
    h2 {
      text-align: center;
    }
    p {
      color: $secondaryBlue;
      font-size: 16px;
      font-weight: 600;
    }
    span {
      color: $secondaryBlue;
    }
  }
}
