@import '../../../../shared/styles/variables.scss';
.ReviewsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ContentSelectorContainer {
  display: flex;
  button {
    margin-right: 14px;
    span {
      padding: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}

.ReviewsInfo {
  border-radius: 20px;
  padding: 24px;
  background: white;
  margin: 12px 0;
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    color: $secondaryBlue;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
  span {
    font-weight: 600;
  }
}

.ProfileExample {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  margin: 12px 0;

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
    color: $secondaryBlue;
    text-align: center;
  }
  img {
    height: 260px;
    width: 470px;
    margin: 8px 0;
  }
}

.ReviewFormSendContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 48px 24px;
  border-radius: 20px;
  background: white;
  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
  }
}

.ProviderReviewAverageContainer {
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;
  padding: 16px 32px 32px 32px;
  background: white;
  margin: 24px 0;
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: 0px;
    color: $secondaryBlue;
    width: 100%;
  }
  div {
    flex-basis: 50%;
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0px;
      margin: 8px 0;
    }
  }
}

.Individual {
  h2 {
    display: none;
  }
  div {
    flex-basis: 100%;
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0px;
      margin: 0;
    }
  }
}

.ProviderReviewPaper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(45, 70, 185, 0.2);
  width: 100%;
  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
  }
}

.ProviderReviewText {
  flex-basis: 60%;
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
  }
}

.ProviderReviewScores {
  flex-basis: 30%;
}
