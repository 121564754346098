@import './variables.scss';

.PTIconText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    font-family: 'Satoshi';
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    margin: 0;
  }
}

.PTAddress {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  p {
    margin: 0;
  }
  span {
    color: $lightGrey;
  }
}
//MUI GLOBAL CSS
.MuiTableCell-root {
  padding: 8px 0px !important;
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.MuiTableCell-body {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  border: 1px solid #e3e5e9 !important;
}
.MuiTableCell-head {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;

  border: 1px solid #e3e5e9 !important;
  background-color: white !important;
  span {
    color: #0b0f31 !important;
    padding: 8px;
  }
}
.MuiTableCell-footer {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  border: 1px solid #e3e5e9 !important;
  background-color: white !important;
  color: #0b0f31 !important;
  span {
    color: #0b0f31 !important;
    padding: 8px;
  }
  p {
    color: #0b0f31 !important;
    padding: 8px;
  }
}
.MuiPaper-elevation2 {
  box-shadow: none !important;
}
.MuiTableRow-hover:hover {
  background: rgba(55, 151, 240, 0.1) !important;
}

.MuiTableSortLabel-active,
.MuiTablePagination-select,
.MuiTablePagination-selectIcon,
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  color: #0b0f31 !important;
}

.MuiTablePagination-select {
  background-color: white !important;
  color: #0b0f31 !important;
}
.MuiTablePagination-selectRoot {
  background-color: white !important;
  color: #0b0f31 !important;
}
