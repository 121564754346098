.dynamicListPagesFooter {
	background-color: black;
	color: white;
	padding: 76px 42px 32px 42px;
	width: 100%;
}

.dlpFooterGrid {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.dlpFooterLogoContainer {
	flex: 1;
	max-width: 300px;

	.dlpFooterBkLogoWhite {
		width: 152px;
		margin-bottom: 16px;
	}

	p {
		margin-bottom: 16px;
		font-size: 0.9rem;
		font-weight: 300;
	}

	.dlpFooterSocialIcons {
		display: flex;
		gap: 15px;

		a img {
			width: 42px;
			height: 42px;
		}
	}
}

.dlpFooterLinksContainer {
	display: flex;
	justify-content: space-between;
	width: 45%;
	max-width: none;
}

.dlpFooterLinksSection {
	flex: 1;
	max-width: 220px;
	padding: 12px 0px;
	margin: 0px 22px;

	a,
	p {
		margin: 0px 0px 10px;
		font-size: 0.9em;
		font-weight: 300;
		color: white;
	}
}

.dlpFooterContactsSection {
	flex: 1;
	max-width: 250px;

	.dlpFooterContactsSectionIcons {
		display: flex;

		a {
			margin-left: 8px;
		}
	}

	p {
		margin: 8px 0px;

		a {
			font-size: 0.9rem;
			color: white;
			text-decoration: none;
			display: block;
		}
	}
}

.dlpFooterBottom {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #333;
	padding-top: 16px;
	text-align: left;

	p {
		font-size: 0.9rem;
		font-weight: 300;

		a {
			color: white;
			text-decoration: none;
			margin: 8px 5px;
		}
	}
}

@media only screen and (max-width: 600px) {
	.dynamicListPagesFooter {
		padding: 18px 12px 8px 12px;
	}

	.dlpFooterGrid {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		margin-bottom: 0px;
	}

	.dlpFooterContactsSection {
		max-width: 100%;
		width: 100%;
		margin-bottom: 20px;
	}

	.dlpFooterLogoContainer {
		.dlpFooterBkLogoWhite {
			width: 152;
			margin-bottom: 0px;
		}

		p {
			margin: 4px 0px 12px 0px;
			font-size: 0.9rem;
			font-weight: 300;
		}

		.dlpFooterSocialIcons {
			display: flex;
			gap: 15px;

			a img {
				width: 42px;
				height: 42px;
			}
		}
	}

	.dlpFooterLinksContainer {
		margin-bottom: 0px;
		width: 100% !important;
	}

	.dlpFooterLinksSection {
		flex: 1;
		padding: 8px 0px;

		a,
		p {
			margin: 0px 0px 10px;
			font-size: 0.9em;
			font-weight: 300;
			color: white;
		}
	}

	.dlpFooterContactsSection {
		.dlpFooterContactsSectionIcons {
			display: flex;

			a {
				margin-left: 8px;
			}
		}

		p {
			margin: 6px 0px;

			a {
				font-size: 0.9rem;
				color: white;
				text-decoration: none;
				display: block;
			}
		}
	}

	.dlpFooterBottom {
		display: block;
		justify-content: space-between;
		padding-top: 0px;
		text-align: left;

		p {
			font-size: 0.8rem;
			font-weight: 300;
			margin: 12px 6px;

			a {
				color: white;
				text-decoration: none;
				margin: 8px 5px;
			}
		}
	}
}
