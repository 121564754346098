@import '../../shared/styles/variables.scss';

.announcement {
	position: relative;
	margin: auto;
	padding: 32px;
	font-size: 1.2rem;
	font-weight: 600;
	background-color: #c20000;
	color: #ffffff;
	border-radius: 16px;
}



.Xbtn {
	position: absolute;
	font-size: 1rem;
	top: 15px;
	right: 20px;
	border: 1px solid white;
	padding: 1px 4px;
	border-radius: 4px;
	cursor: pointer;
}

.css-uhb5lp {
	border-radius: 16px;
}

// body {
//   font-family: 'Satoshi', sans-serif !important;
//   width: 100vw;
//   margin: 0 auto;
//   padding: 0;
//   max-width: 1440px !important;
// }

.flexWrap {
	display: flex;
	flex-wrap: wrap;
}

.SFTitleContainer {
	width: 100%;
	padding: 0px;
	font-size: 26px;
}

.SFLabel {
	margin-bottom: 40px;
	font-weight: 700;
	font-size: 12px;
	line-height: 18px;
	color: #ffffff;
	padding: 4px 12px;
	gap: 10px;
	height: 26px;
	width: fit-content;
	background: #1c32f3;
	border-radius: 50px;
}

.SFWarningIncluded {
	display: flex;
	flex-direction: column;
	min-width: 360px;
	width: 100%;
	padding-top: 40px;
}

.SFWarningText {
	background-color: #f8f8fc;
	border: 1px solid #eae9f0;
	border-radius: 8px;
	padding: 16px;
	text-align: left;
}

.SFMarginBot2 {
	margin-bottom: 2em;
}

.SFContainer {
	position: relative;
	width: 100vw;
	height: 100vh;
	min-width: 360px;
	max-width: 1440px !important;
	padding: 24px 12px;
	margin: 0 auto;
	display: flex;
}

.TableMode {
	padding: 0;
}

.SFLeftInfoArea {
	width: 430px;

	max-height: 100vh;
}

.SFRightInfoArea {
	width: 430px;

	max-height: 100vh;
}

.SFLeftTitleContainer {
	margin: 48px;
}

.headerSubText {
	font-family: 'Satoshi';
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: rgba(255, 255, 255, 0.75);
	margin-top: 16px;
	margin-bottom: 0 !important;
}

.headerText {
	font-family: 'Satoshi';
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
	letter-spacing: 0em;
	text-align: left;
	margin: 0 0 24px;
}

.SFLeftTitle {
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: 0em;
	text-align: left;
	margin: 0;
}

.SFLeftSubTitle {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	margin: 0;
}

.css-9l3uo3 {
	font-family: 'Satoshi';

	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
}

.rocketman {
	width: 230px;
}

.blueaccountant {
	width: 209px;
	position: absolute;
	right: 0;
	bottom: 0;
}

.stepperContainer {
	font-size: 13px;
}

.SFContentArea {
	width: 580px;
	min-width: 580px;

	gap: 10px;
	box-shadow: 0px 10px 20px 0px #0b0f311a;

	border-radius: 18px;
}

.SFNameInput {
	width: 100%;
}

.SFOfferArea {
	width: 1200px;
	min-width: 580px;
	margin: 0 auto;
}

.rfpButton {
	width: fit-content !important;
}

.bkoffergoodnews {
	font-family: Satoshi;
	font-size: 20px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
}

.flexWrapGap8 {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}

.SFFlexWrapGap8 {
	// display: flex;
	// flex-wrap: wrap;
	// gap: 8px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 16px;
}

.SFContentHeader {
	position: relative;
	color: white;
	width: 100%;
	min-height: 126px;
	max-height: 126px;
	padding: 24px;
	border-radius: 16px 16px 0px 0px;
	background: linear-gradient(85.22deg, #0b1fcb 0%, #9747ff 92.28%);
}

// .marginTop28 {
//   margin-top: 28px;
// }

.andiHelps {
	position: fixed;
	top: 0;
	right: 0;
	margin-top: 30px;
	margin-right: 20px;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	z-index: 300;
	width: 170px;
	height: fit-content;
	background: #ffffff;
	border: 2px solid #1c32f3;
	box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
		0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12) !important;
	align-items: center;
	border-radius: 16px;
	padding: 20px 0;
}

.headerImg {
	position: absolute;
	bottom: 0;
	right: 10px;
}

.andiimg {
	width: 100px;
	height: 100px;
}

.noprovcontainer {
	width: 100%;
	max-width: 580px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	color: black;
}

.noprovheader {
	color: white;
	position: relative;
	width: 100%;
	height: 164px;
	padding: 24px;
	gap: 24px;
	border-radius: 18px;
	background: linear-gradient(80.59deg, #0b1fcb -1.38%, #9747ff 102.2%);
}

.noprovtitle {
	font-family: 'Satoshi';
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
	text-align: left;
	color: rgba(11, 15, 49, 1);
	margin-bottom: 0;
}

.noprovtext {
	font-family: 'Satoshi';
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-align: left;
	color: rgba(80, 85, 129, 1);
	margin-top: 4px;
	margin-bottom: 18px;
}

.noprovbuttoncontainer {
	width: 100%;
	height: 80px;
	padding: 18px;
	gap: 0px;
	border-radius: 12px;
	border: 1px solid rgba(111, 116, 165, 0.5);
	display: flex;
	justify-content: space-between;
}

.SFTracker {
	position: sticky;
	-webkit-position: sticky;
	-moz-position: sticky;
	-ms-position: sticky;
	-o-position: sticky;
	top: 0px;
	background: white;
	z-index: 1000;
	margin-bottom: 20px;
}

// .SFNeedHelp {
//   color: $babyBlue;
//   width: 70%;
//   font-weight: bold;
//   //line-height: 28px;
// }

.SFTitle {
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: 0em;
	text-align: left;
}

.SFSubTitle {
	color: #0b0f31;
	margin-left: 0px;
	margin-top: 0px;
	margin-bottom: 8px;
	font-family: 'Satoshi';
	font-size: 16px !important;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.SFSubTitleHelper {
	font-family: Satoshi;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #6f74a5;
	margin-top: 0;
}

.SFIndustrySpecSubTitle {
	color: #0b0f31;
	margin: 16px 0px 6px 0px;
	font-family: 'Satoshi';
	font-size: 16px !important;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.SFVerticalBlue {
	width: 2px;
	height: 24px;
	background: #1c32f3;
	border: 1px solid #1c32f3;
	margin-right: 8px;
}

.SFTitleBlue {
	font-weight: 700;
	font-size: 24px;
	line-height: 30px;
	color: #1c32f3;
	margin: 0;
	max-width: 90%;
}

.SFInputContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.SFlimited {
	margin: 0 40px;
}

.SFInput {
	box-sizing: border-box;
	padding: 0;
	width: 100%;

	display: flex;
	flex-direction: column;
}

.stepContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 16px 16px 0px 16px;

	display: flex;
	flex-direction: column;

	max-height: calc(100vh - 174px);
	overflow-y: scroll;
}

.finalStepContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0 auto;

	flex-direction: column;

	overflow-y: scroll;
}

.offerStepContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 16px 20px 0px 20px;

	display: flex;
	flex-direction: column;

	max-height: calc(100vh - 174px);
	overflow-y: scroll;
}

.SFOfferContainer {
	width: 800px;
}

.SFOfferContainerFakeCherry {
	width: 70%;
}

.SFSticky {
	position: sticky;
	-webkit-position: sticky;
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	top: 0;
	background-color: white !important;
	z-index: 100;
}

.SFoffertitle {
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
}

.SFoffertext {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	color: #505581;
}

.SFOfferCheckboxContainer {
	width: 100%;
}

.SFcouponInput {
	width: 190px;
	height: 44px;
	padding: 12px;
	gap: 8px;
	border-radius: 6;
	border: 1px solid #e3e5e9;
}

.NewCouponCheck {
	width: 106px;
	height: 44px;
	padding: 12px 16px;
	border-radius: 6px;
	background-color: #4d5bec;
	color: white;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-transform: none;
	margin-top: 6px;
}

.NewCouponCheck:hover {
	background-color: #4d5bec;
	color: #989dce;
}

.SFResultNavContainer {
	position: sticky;
	-webkit-position: sticky;
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	bottom: 0;
	border: 1px solid #e3e5e9;
	border-radius: 12px;
	box-shadow: 0px -5px 5px 0px rgba(111, 116, 165, 0.05);
	margin-top: 32px !important;
}

.SFDiscountinfoContainer {
	position: sticky;
	-webkit-position: sticky;
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	top: 0;
	// width: 400px;
	width: 30%;
	height: 100%;
	// padding: 18px 24px 0 36px;
	padding: 18px 24px 0 36px;
}

.discountDiv {
	padding: 12px;
	gap: 8px;
	border-radius: 6px;
	border: 1px solid #e3e5e9;

	span {
		display: flex;
		align-items: center;
		font-family: 'Satoshi';
		font-size: 14px;
		font-weight: 700;
		color: #0b0f31;

		text-align: left;
	}

	img {
		margin-right: 8px;
	}

	p {
		margin: 4px 0;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		color: #505581;
	}
}

.SFinfoContent {
	font-family: 'Satoshi';
	font-size: 14px !important;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	background: #fafafa;
	padding: 12px;
	gap: 12px;
	border-radius: 6;

	img {
		margin-right: 8px;
	}

	span {
		display: flex;
		align-items: center;
		font-family: 'Satoshi';
		font-size: 14px;
		font-weight: 700;
		color: #0b0f31;

		text-align: left;
	}

	p {
		margin: 0;
		font-size: 14px !important;
		font-weight: 500;
		color: #505581;
	}
}

.SFinfoTitle {
	font-family: 'Satoshi';
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	margin: 8px 0;
}

.questionTracker {
	width: 28px;
	height: 24px;
	padding: 6px;
	border-radius: 4px;
	gap: 6px;
	background: #f9f9fa;
	font-family: 'Satoshi';
	font-size: 10px;
	font-weight: 700;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: left;
	color: rgba(163, 166, 195, 1);
}

.SFResultHeader {
	padding: 24px;
	border-radius: 18px;
	gap: 24px;

	position: relative;
	width: 100%;
	height: 200px;
	background: linear-gradient(80.59deg, #0b1fcb -1.38%, #9747ff 102.2%);
	color: white;
}

.padding20 {
	padding: 20px !important;
}

// .SFInputField {
//   width: 500px;
// }

.css-ahj2mt-MuiTypography-root {
	font-family: 'Satoshi';
	font-size: 14px !important;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
}

.SFRadioInput {
	border: 2px solid #e3e5e9;
	border-radius: 8px;
	height: 52px;
	width: 100%;
	margin: 4px 0;
	font-family: 'Satoshi';
	font-size: 14px !important;
	font-weight: 500;
	color: #424559;
	line-height: 20px;
	letter-spacing: 0em;
	display: flex;
	justify-content: space-between;
	padding-left: 12px;
	//background: #e3e5e940;
}

.SFresultStars {
	position: absolute;
	top: 0px;
	right: 10px;
}

.prioContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
}

.qualityItem {
	font-family: 'Satoshi';
	font-size: 10px;
	font-weight: 700;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;

	padding: 4px 6px;
	border-radius: 6px;
	background: rgba(255, 255, 255, 0.1);
}

.FlexRow {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.SFQuestion {
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	max-width: 90%;
	//color: #1c32f3;
	letter-spacing: 0.02em;
	margin-top: 0;
	margin-bottom: 0;
}

.SFSubText {
	font-weight: 500;
	font-size: 16px;
	line-height: 30px;
	color: #333c45;
}

.SFBold {
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.02em;
	color: #000b16;
	margin: 0 25px;
}

.SFPosRel {
	position: relative;
}

.SFImage {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(0, -124px);
	z-index: 100;
	max-height: 150px;
}

.SFStr {
	font-weight: 900;
}

.SFResImage {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(0, -160px);
	z-index: 100;
}

.HRBlue {
	border: 1px solid #1c32f3;
	width: 100%;
}

.CoNrCont {
	display: flex;
	justify-content: space-evenly;
	width: 100%;
}

.SFResServDrop {
	background-color: $azureBlue;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: white;
	padding: 12px;
	margin: 0;
}

.SFX1 {
	display: flex;
	justify-content: space-between;
	color: #0b0f31;
}

.SFX2 {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}

.SFflex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.SFflex2 {
	display: flex;
	flex-wrap: wrap;

	justify-content: space-between;
}

.SFflexOnly {
	display: flex;
}

.SFResultBody {
	display: flex;
	flex-wrap: nowrap;
}

.SFResultBodyFakeCherry {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.SFCentered {
	text-align: center;
}

.SFMarginVert2rem {
	margin: 2rem 0;
}

.SFMarginTop40 {
	margin-top: 40px;
}

.SFMarginTop70 {
	margin-top: 70px;
}

.SFMarginTop1rem {
	margin-top: 1rem;
}

.SFMarginTop2rem {
	margin-top: 2rem;
}

.SFMarginVert2rem {
	margin: 2rem 0;
}

.SFResults {
	margin-top: 40px;
	padding: 32px;
	background: #ccfff0;
	border: 1px solid #00fdb1;
	border-radius: 0px 0px 12px 12px;
}

.jss1 {
	position: relative !important;
	//border: none !important;
}

.SFPricesContainer {
	padding: 40px;
	background: #f0f2fe;
	border: 1px solid #f0f2fe;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

.SFBlueArea {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	background: #1c32f3;
	border: 1px solid #1c32f3;
	height: 6rem;
	padding: 2rem;
}

.SFResSubCont {
	padding: 16px;
	background: #f0f2fe;
	border-radius: 12px;
	border: 1px solid #1c32f3;
	box-sizing: border-box;
	width: 20%;
}

.SFResSubCont2 {
	padding: 16px;
	background: #f0f2fe;
	border-radius: 12px;
	border: 1px solid #1c32f3;
	box-sizing: border-box;
	width: 19%;
}

.finalBtnContainer {
	display: flex;
	justify-content: center;
	margin: 3rem auto;
}

.margin12 {
	margin: 12px;
}

.SFResServicesContainer {
	min-width: 45%;
	max-width: 90%;
	background: #ffffff;
	border-radius: 12px;
	padding: 16px;
	height: fit-content;
	margin-top: 1rem;
	width: 90%;
}

.SFCheckInput {
	padding: 14px;

	border-radius: 0 0 12px 12px;
}

.SFCheckInputNoPadding {
	padding: 0;

	border-radius: 0 0 12px 12px;
}

.SFResultBold {
	font-weight: 700;
	font-size: 16px;
	line-height: 30px;
	color: #000b16;
	margin: 0;
}

.SFResultBoldWhite {
	font-weight: 700;
	font-size: 24px;
	line-height: 30px;
	color: #ffffff;
	margin: 0;
}

.SFResultNr {
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 50px;
	color: #1c32f3;
	margin: 0 1rem 0 0;
}

.SFReqBtnCont {
	display: flex;
	justify-content: space-evenly;
}

.SFReqCenter {
	display: flex;
	justify-content: center;
}

.SFReqEnd {
	display: flex;
	justify-content: flex-end;
}

.SFBtn {
	width: 250px !important;
	height: 160px !important;
	font-size: '20px' !important;
	padding: '16px' !important;
	margin: '16px' !important;
}

.SFSmallBtn {
	width: 180px !important;
	height: 120px !important;
	font-size: '16px' !important;
	padding: '16px' !important;
	margin: '16px' !important;
}

.SFMicroBtn {
	width: 120px !important;
	height: 60px !important;
	font-size: '14px' !important;
	padding: '10px' !important;
	margin: '10px' !important;
}

.SFNavContainer {
	margin-top: 80px;
}

.SFInputWidth20 {
	width: 20%;
}

.SFInputWidth25 {
	width: 25%;
}

.SFInputWidth30 {
	width: 30%;
}

.SFInputWidth35 {
	width: 35%;
}

.SFInputWidth45 {
	width: 45%;
}

.SFInputWidth60 {
	width: 60%;
}

.SFInputWidth75 {
	width: 75%;
}

.SFInputWidth85 {
	width: 85%;
}

.SFInputWidth100 {
	width: 100%;
}

// .maringBottom2rem {
//   margin-bottom: 2rem;
// }

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
	width: 100%;
}

.css-9l3uo3 {
	width: 100%;
}

.SFCheckboxLabelContainer {
	margin: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-width: 100%;
}

.SFCheckboxLabel {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: rgb(66, 69, 89);
	font-family: 'Satoshi', sans-serif;
	margin-left: 0px;
	margin-right: auto;
}

.SFCheckboxLabelPrice {
	font-family: 'Satoshi', sans-serif !important;
	color: rgb(66, 69, 89);
	font-size: 16px;
	font-weight: 700;
	line-height: 30px;
}

.css-1f2kvjf-MuiFormControlLabel-root .MuiFormControlLabel-label {
	width: 100%;
}

.SwitchContainer {
	display: flex;
	gap: 12px;
}

.SwitchItem {
	padding: 12px 12px 0px 12px;
	width: 265px;
	height: 190px;
	border-radius: 6px;
	border: 1px solid #e3e5e9;
	position: relative;

	p {
		font-size: 12px;
		margin: 2px 0;
	}
}

.SwitchItem:hover {
	cursor: pointer;
	background-color: '#FDD500';
}

.switchOptionTitle {
	font-size: 14px !important;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0em;
	margin-bottom: 8px !important;
	text-align: center;
}

.selectedSwitch {
	background: #f4f5fe;
	border: 1px solid #1c32f3;
}

.providerSwitch {
	width: 258px;
	border: 1px solid rgba(227, 229, 233, 1);
	position: relative;
	border-radius: 6px;
}

.SFchoicetitle {
	font-family: 'Satoshi';
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	text-align: left;
	padding: 9px 12px;
	margin: 0;
}

.SFchoicetext {
	font-family: 'Satoshi';
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	color: rgba(11, 15, 49, 1);
	padding-left: 12px;
	margin: 0;
}

.SFchoicePriceContainer {
	margin: 0;
	padding: 6px 12px;
	display: flex;
	justify-content: space-between;
}

.SFchoicePriceText {
	font-family: 'Satoshi';
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	color: rgba(80, 85, 129, 1);
}

.SFchoicePrice {
	font-family: 'Satoshi';
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	text-align: left;
	color: rgba(11, 15, 49, 1);
}

.switchoptimg1 {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 80px;
}

.switchoptimg2 {
	position: absolute;
	bottom: 0;
	left: 0;
	width: '56px';
}

.provswitchonimg {
	position: absolute;
	top: 0;
	right: 0;
}

.switchonimg {
	position: absolute;
	bottom: 0;
	right: 0;
}

.SFswitchhint {
	font-family: Satoshi;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	color: #505581;
	margin: 8px 0 4px;
}

// .SFServicesContainer {
//   display: flex;
//   flex-wrap: wrap;
//   width: 60%;
//   padding: 0 1em;
// }

// .SFServicesContainer > div {
//   flex: 50%;
// }

.SFButtonContainer {
	display: flex;
	position: sticky;
	-webkit-position: sticky;
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	bottom: 0;
	margin-top: auto;
	z-index: 500;
	background: white;
	width: 100%;
	height: 76px;
	padding: 16px;
	justify-content: space-between;
}

.progressContainer {
	width: 100%;
	height: 12px;
	background: #f0f2fe;
	box-shadow: inset 0px 2px 4px rgba(17, 30, 146, 0.32);
	border-radius: 18px;
	margin-bottom: 54px;
}

.progressBar {
	height: 12px;
	background: linear-gradient(180deg, #0bd142 0%, #08a033 100%);
	box-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.8);
	border-radius: 18px;
}

.newlyFCont {
	max-width: 45%;
}

.bannerContainer {
	margin: 2em 0 2em 2em;
	width: 45%;
}

.bannerImg {
	max-width: 100%;
}

.btnMaxWidth130 {
	max-width: 80px;
}

.btnMaxWidth250 {
	max-width: 250px;
}

.SFMaxWidth45 {
	max-width: 49%;
}

.SFMinWidth40 {
	min-width: 40%;
}

// .LastBtnsContainer {
//   display: flex;
//   justify-content: space-between;
// }
// .LastBtns {
//   font-size: 20px !important;
//   max-width: 270px;
//   height: auto;
//   padding: 1em auto;
//   border-radius: 8px;
//   margin-top: 1.5rem !important;
// }
.AdornmentInputContainer {
	background-color: white;
	max-height: fit-content;

	div {
		margin: 0 !important;
		flex: none;
	}

	img {
		width: 16px;
		height: 16px;
		margin: 0 8px 0 0;
	}
}

// .btnsCol {
//   max-width: 270px;
//   font-size: 18px;
//   font-weight: 700;
//   margin-bottom: 1.5rem;
// }

.SFHelpContainer {
	display: flex;
	justify-content: space-evenly;
	margin-top: 10rem;
	background-color: #f0f2fe;
	min-height: 320px;
	// position: absolute;
	// bottom: 40px;
	// right: 0;
}

.helpTextContainer {
	align-self: center;
	max-width: 40vw;
}

.helperFigureContainer {
	align-self: flex-end;
}

.otpbanner {
	margin: 20px;
	display: flex;
	justify-content: center;
}

.fakeprofile {
	max-width: 250px;
	min-width: 150px;
	height: 98px;
	padding: 8px;
	border-radius: 12px;
	gap: 18px;
	background: rgba(255, 255, 255, 0.15);
	position: relative;
}

.fakeprofiletop {
	display: flex;
}

.bkoffertitle {
	margin-top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: 'Satoshi';
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
}

.resultnrline {
	max-width: 80%;
	font-family: 'Satoshi';
	font-size: 20px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: rgba(255, 255, 255, 0.75);
}

.bkp {
	font-family: 'Satoshi';
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #ffffffbf;
	margin-top: 0;
}

.fakeprofilename {
	margin-top: 2px;
	margin-left: 12px;
	margin-bottom: 12px;
	font-family: 'Satoshi';
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: rgba(255, 255, 255, 1);
}

.providerCards {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
}

.furtherfakes {
	width: 160px;
	height: 98px;
	padding: 8px;
	border-radius: 12px;
	gap: 18px;
	background: rgba(255, 255, 255, 0.15);
}

// ----------- F O N T S --------------

.desktopH3Blue {
	font-weight: 700;
	font-size: 28px;
	line-height: 40px;
	color: #1c32f3;
	margin: 0;
}

.desktopH2Blue {
	font-weight: 700;
	font-size: 2rem;
	line-height: 30px;
	color: #1c32f3;
	margin: 0 auto;
}

.pBlue {
	font-weight: 700;
	font-size: 18px;
	line-height: 30px;
	color: #1c32f3;
	letter-spacing: 0.02em;
}

.pDarkGrey {
	font-family: 'Satoshi', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 30px;
	color: #333c45;
}

.pDarkGreyB {
	font-family: 'Satoshi', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 30px;
	color: #333c45;
	margin-bottom: 0;
}

.Uppercase {
	text-transform: uppercase;
}

.SFNextButton {
	background-color: #1c32f3 !important;
	border-radius: 30px !important;
	width: 7em;

	span {
		color: white !important;
		text-transform: none;
	}
}

.dualBtn {
	padding: 20px;
	display: flex;
	justify-content: space-evenly;
	flex-direction: row;
	align-items: center;
}

// .SFSmallText {
//   color: black;
//   font-size: 14px;
//   font-weight: 400;
//   line-height: 24px;
// }
.SFTransparentBlue {
	width: 7em;
	height: 2.6em;
	border-radius: 30px !important;

	span {
		color: #1c32f3 !important;
		text-transform: none;
		padding: 5px;
	}
}

.SFSendReq {
	background-color: $strongBlue !important;
	border-radius: 30px !important;
	width: 12em;
	height: 2.6em;

	span {
		color: white !important;
		text-transform: none;
		padding: 5px;
	}
}

// .SFReqSent {
//   background-color: $greenHaze !important;
//   border-radius: 30px !important;
//   width: 12em;
//   height: 2.6em;
//   span {
//     color: white !important;
//     text-transform: none;
//     padding: 5px;
//   }
// }

// .SFResultHighlight {
//   font-size: 1.3em;
//   font-weight: 700;
//   color: $royalBlue;
// }

// .SFHighlightText {
//   font-size: 1em;
//   font-weight: 700;
//   color: $royalBlue;
// }

// .SFSortingHeader {
//   height: 50px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 16px;
// }

.SFKnetPlusContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px;
	width: 410px;
	border-radius: 20px !important;
}

.SFFormContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

// .SFMobileP {
//   font-size: 12px;
//   line-height: 24px;
//   color: black;
//   margin: 0;
// }
// .SFPPContent {
//   padding: 0px 32px 32px 0px;
// }
.react-tel-input .form-control {
	width: 100% !important;
	height: 56px !important;
}

// .yellowHR {
//   height: 2px;
//   background: $webOrange;
//   border: none;
//   margin: 2rem auto;
// }
// .bulletPoint {
//   display: inline-block;
//   width: 15px;
//   height: 15px;
//   border: 1px solid $webOrange;
//   border-radius: 5px;
//   background: $webOrange;
//   color: $webOrange;
//   //margin: 7px 0px 2px 0px;
// }

// .PaddingTop2 {
//   padding-top: 2px;
// }

// .leftMargin20 {
//   margin-left: 20px;
// }

// .BPRow {
//   display: flex;
//   margin-bottom: 1rem;
// }

// .BPContainer {
//   max-height: 100%;
// }

.SFFlowSwitchEmailTitle {
	font-size: 16px;
}

.SFFlowSwitchEmailTextArea {
	font-family: 'Satoshi';
	font-size: 1rem;
	font-weight: 500;
	width: 100%;
	margin: 8px 0px;
	padding: 16.5px 14px;
	resize: none;
	outline: none;
	border: 1px solid #e3e5e9;
	border-radius: 6px;
}

.SFFlowSwitchEmailTextArea:focus {
	border: 1px solid #e3e5e9;
	outline: none;
}

.SFFlowSwitchEmailError {
	color: red;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 8px;
	font-weight: '500';
}

@media only screen and (min-width: 600px) and (max-width: 1100px) {
	// body {
	//   width: 100% !important;
	// }

	// .App {
	//   width: 100%;
	// }

	.SFResServicesContainer {
		min-width: 400px;
		max-width: 100%;
		background: #ffffff;
		border-radius: 12px;
		padding: 16px;
		height: fit-content;
		margin-top: 1rem;
		width: 100%;
	}

	.SFLeftInfoArea {
		display: none;
	}

	.SFRightInfoArea {
		display: none;
	}

	.SFContainer {
		margin: 0;
		justify-content: center;
	}
}

@media only screen and (min-width: 600px) and (max-width: 700px) {
	.SFDiscountinfoContainer {
		width: 20%;
		padding: 0px;
	}

	.SFOfferContainerFakeCherry {
		width: 80%;
	}
}

@media (max-width: 600px) {
	#content-container {
		width: 100%;
	}

	// .App {
	//   width: 100% !important;
	// }

	html {
		width: 100% !important;
	}

	// body {
	//   width: 100% !important;
	//   height: 100vh !important;
	// }

	.Header {
		width: 90% !important;
	}

	.h2mobile {
		font-size: 20px !important;
	}

	p {
		margin: 24px 8px;
		font-size: 0.8rem;
	}

	.SFLabel {
		margin-bottom: 12px;
	}

	.SFWarningIncluded {
		margin-top: 16px;
	}

	.SFContentHeader {
		max-height: 140px;
		border-radius: 0px 0px 16px 16px;
		padding: 16px;
		height: 140px;
	}

	.headerSubText {
		font-family: 'Satoshi';
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		max-width: 80%;
		color: rgba(255, 255, 255, 0.75);
		margin-top: 16px;
		margin-bottom: 0 !important;
		margin-left: 0 !important;
	}

	.headerText {
		font-family: 'Satoshi';
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
		margin: 0 0 24px;
		max-width: 80%;
	}

	.SFContentArea {
		min-width: 100%;
		width: 100%;
		//height: 100vh;
		box-shadow: none;
	}

	.stepContainer {
		padding: 16px 16px 0px 16px;
		margin: 0;
		width: 100%;
		max-height: max-content;
		overflow: visible;
	}

	.SFoffertitle {
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0em;
		margin: 8px 0 0;
	}

	.SFoffertext {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		margin: 0;
	}

	.SFoffertextFakeCherry {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		margin: 8px 0px 16px 0px;
	}

	.SFInputContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.SFReqBtnCont {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}

	.SFInput {
		box-sizing: border-box;
		padding: 0px;
		margin: 0px;
		gap: 0px;
		//display: flex;
		//justify-content: space-evenly;
		// margin: 40px 0;
		//flex-wrap: wrap;
		//height: 100%;
	}

	.helperFigureContainer {
		display: none;
	}

	.SFLeftInfoArea {
		display: none;
	}

	.SFRightInfoArea {
		display: none;
	}

	.SFOfferArea {
		width: 1200px;
		min-width: 300px;
		margin: 0 auto;
	}

	.SFButtonContainer {
		border-top: 1px solid #e3e5e9;
		padding: 16px 0;
		position: sticky;
		-webkit-position: sticky;
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		bottom: 0;
		box-shadow: 0px -5px 5px 0px #6f74a50d;
	}

	.helpTextContainer {
		align-self: center;
		max-width: 100%;
		padding: 24px;
	}

	.LastBtnsContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	.LastBtns {
		font-size: 16px !important;
		margin-top: 2rem !important;
	}

	.helpButtonContainer {
		text-align: center;
	}

	.SwitchContainer {
		width: 100%;
		flex-direction: column;
		align-items: center;
	}

	.SwitchItem {
		width: 100%;
		height: 220px;
		line-height: 16px !important;
		font-size: 12px;
	}

	.SFResImage {
		display: none;
	}

	.SFContainer {
		padding: 0;
	}

	//   .SFPPContent {
	//     padding: 16px 0px 32px 0px;
	//   }
	.SFTracker {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 0;
		height: min-content;
		flex: none;
		background-color: transparent;
	}

	.SFImage {
		display: none;
	}

	.dualBtn {
		padding: 20px;
		display: flex;
		justify-content: space-evenly;
		flex-direction: column;
		align-items: center;
	}

	.SFTitleContainer {
		width: 100%;
		padding: 0px;
		font-size: 26px;
	}

	.SFTitle {
		font-size: 26px;
		line-height: 30px;
	}

	.SFTitleBlue {
		font-size: 18px;
		line-height: 26px;
		max-width: 100%;
	}

	.providerSwitch {
		width: 90%;
		display: flex;
		flex-direction: column;
	}

	.fakeprofile {
		display: none;
	}

	.finalStepContainer {
		overflow: visible;
	}

	.SFResultHeader {
		max-width: 100vw;
		width: 100%;
		height: 150px;
		background: linear-gradient(80.59deg, #0b1fcb -1.38%, #9747ff 102.2%);
		color: white;
		padding: 16px;
		border-radius: 0px 0px 16px 16px;
	}

	.SFResultBold {
		font-size: 14px;
	}

	.SFResultBody {
		flex-direction: column;
	}

	.SFResultBodyFakeCherry {
		flex-direction: column;
	}

	.SFOfferContainer {
		max-width: 100vw;
		padding: 0 16px;
	}

	.SFResSubCont {
		padding: 12px !important;
		font-size: 16px !important;
	}

	.valuesContainer {
		flex-wrap: wrap;
	}

	.prioContainer {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	.bkoffergoodnews {
		font-family: Satoshi;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
		margin: 16px 0 0;
	}

	.bkoffertitle {
		font-size: 10px;
		font-weight: 500;
		line-height: 10px;
		letter-spacing: 0em;
	}

	.resultnrline {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
		margin-top: 0;
		margin-bottom: 0px;
	}

	.SFPricesContainer {
		padding: 6px;
	}

	.discountinfoContainer {
		max-width: 100vw;
		width: 100%;
		height: 100%;
		padding: 16px;
	}

	.SFDiscountinfoContainer {
		max-width: 100vw;
		width: 100%;
		height: 100%;
		padding: 0px 16px 72px 16px;
	}

	.SFMarginTop40 {
		margin-top: 16px;
	}

	.SFMarginTop70 {
		margin-top: 16px;
	}

	.SFSubText {
		margin: 0;
		font-size: 12px;
	}

	.SFResServicesContainer {
		width: 100%;
		max-width: 100%;
	}

	//   .SFTitle {
	//     font-size: 20px;
	//     line-height: 24px;
	//     font-weight: 600;
	//     margin: 6px 0px 16px 0px;
	//   }
	.SFQuestion {
		font-size: 14px;
		line-height: 18px;
	}

	//   .SFResults {
	//     font-size: 16px;
	//     line-height: 24px;
	//     font-weight: 600;
	//     width: 100%;
	//   }
	//   .SFServicesContainer {
	//     width: 100%;
	//   }
	.SFCheckboxLabel {
		font-size: 12px !important;
		line-height: 20px;
		display: flex;
		align-items: center;
	}

	.SFCheckboxLabelPrice {
		font-size: 12px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
	}

	.SFX1 {
		display: flex;
		justify-content: space-between;
		color: #0b0f31;
	}

	.SFX2 {
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
	}

	.SFResultNavContainer {
		position: sticky !important;
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		-webkit-position: sticky;
		bottom: 0;
		box-shadow: none;
		border: none;
		border-top: 1px solid #e3e5e9;
		border-radius: 0;
		padding: 12px;
	}

	.progressContainer {
		box-shadow: none;
	}

	.progressBar {
		box-shadow: none;
	}

	.SFNameInput {
		width: 100%;
	}

	.switchoptimg1 {
		max-width: 50px;
	}

	.switchoptimg2 {
		max-width: 35px;
	}

	// .SFInputField {
	//   margin-top: 24px;
	//   width: 250px;
	// }

	.SFInputWidth20 {
		width: 100%;
	}

	.SFInputWidth25 {
		width: 100%;
	}

	.SFInputWidth30 {
		width: 100%;
	}

	.SFInputWidth35 {
		margin-top: 24px;
		width: 250px;
	}

	.SFInputWidth45 {
		width: 100%;
	}

	.SFInputWidth60 {
		width: 100%;
	}

	.SFInputWidth75 {
		width: 100%;
	}

	.SFInputWidth85 {
		width: 100%;
	}

	.SFCentered {
		display: none;
	}

	//   .MarginH12Mobile {
	//     margin: 12px 0px;
	//   }
	//   .SFSmallText {
	//     font-size: 12px;
	//     line-height: 18px;
	//   }
	//   .PaddingTop2 {
	//     padding-top: 0px;
	//   }
	.bannerContainer {
		width: 100%;
		margin: 1em 0;
	}

	.SFFlex2 {
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
	}

	.SFResSubCont {
		width: 100%;
		padding: 24px;
		margin: 16px 0;
	}

	.SFMaxWidth45 {
		max-width: 100%;
	}

	.flexWrap {
		display: flex;
		flex-wrap: wrap;
	}

	.newlyFCont {
		max-width: 100%;
	}

	.bannerImg {
		max-width: 100%;
	}

	.CoNrCont {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
	}

	.announcement {
		margin: auto;
		padding: 16px;
	}

	.headerImg {
		width: 80px;
	}

	.SFOfferContainerFakeCherry {
		width: 100%;
		padding: 0px 12px 0px 12px;
	}

	.SFFlexWrapGap8 {
		// display: flex;
		// flex-wrap: wrap;
		// gap: 8px;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 16px;
		// width: 100%;
	}
}
