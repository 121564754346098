@import './variables.scss';
button {
	/*   display: block; */
	margin: 0 auto;
	transition: 0.3s;
	font-size: 0.9em;
	padding: 0.8em 1.5em;
	border-style: solid;
	border-width: 1px;
	border-radius: $border-radius;
	font-weight: 700;

	border-color: #81d2f0;
	color: $pureWhite;
	cursor: pointer;
}
body {
	//overflow-x: hidden;
	font-family: 'Satoshi', sans-serif !important;
}
section {
	// padding: 6em 2em;
	// background: $mercuryGradient;
	width: 100%;
}
.azure-blue {
	color: $azureBlue;
}
h1,
h2 {
	margin: 0;
	padding: 0;
}
h2 {
	font-size: 24px;
	padding-top: 0.5em;
	margin-bottom: 0.5em;
	@media (max-width: 600px) {
		font-size: 1.1em;
	}
}
.jss1 {
	position: relative !important;
	//border: none !important;
}
hr {
	width: 100%;
	color: $blumine;
	opacity: 0.6;
	margin: 9px 0;
}
a {
	text-decoration: none;
}
.no-padding {
	padding: 0 !important;
}
.no-margin {
	margin: 0;
}
.margin-vertical {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}
.MarginRight2em {
	margin-right: 2em !important;
}
.right-align {
	width: 100%;
	text-align: right;
}
.left-align {
	width: 100%;
	text-align: left;
}
.center-align {
	width: 100%;
	text-align: center;
}
.bold {
	font-weight: bold;
}
.flex-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex-top-right {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
}
.flex-space-between {
	display: flex;
	justify-content: space-between;
}
.column {
	flex-direction: column;
}
.row {
	flex-direction: row;
}
.full-width {
	width: 100%;
}
.extrapadding {
	padding-top: 1em;
	padding-bottom: 1em;
}
.header-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: $castilWhite;
	padding: 0.5em;
}

.catalina-blue {
	background-color: $catalinaBlue !important;
}
.spray {
	background: $sprayBlue !important;
}
.light-grey {
	background: $lightGrey !important;
}
.green {
	background: rgb(121, 206, 121) !important;
}
.white-text {
	span {
		color: white !important;
	}
}
.knetplus-form-container {
	position: fixed;
	width: 17%;
	top: 10.2%;
	right: 0.3%;
	opacity: 0.5;
	text-align: center;
	@media (max-width: 1900px) {
		position: relative;
		top: 0;
	}
}
.submit-request-container {
	background: $castilWhite !important;
}

.knetplus-form-container:hover {
	opacity: 1;
}

#content-container {
	flex: 1;
	display: flex;
	/* background-image: linear-gradient(-90deg, #ffffff, #e8e8e8, #ffffff);
 */
}

.low-margin {
	margin: 0.2em !important;
}
.min-max-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: -1em;
	margin-left: -0.5em;
	margin-right: -0.5em;
}
.RangeSliderForLimit {
	justify-content: space-between;
	display: flex;
	color: white;
	margin-bottom: 1em;
	flex-wrap: wrap;
}

.RangeSlider {
	padding: 0.2em;
}

.center-child {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.center-child-mid {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.title-container-switch {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: $castilWhite;
	//padding: 0.5em;
	@media (max-width: 1200px) {
		font-size: 0.9em;
	}
	@media (max-width: 1080px) {
		font-size: 0.78em;
	}
	@media (max-width: 1000px) {
		flex-direction: column;
		align-items: flex-end;
	}
	@media (max-width: 830px) {
		font-size: 0.7em;
	}
	@media (max-width: 780px) {
		font-size: 0.8em;
		align-items: flex-start;
	}
}
.MuiTableCell-alignLeft {
	text-align: center !important;
}
.MuiGrid-root .MuiFormControl-root {
	width: 100%;
}

/* .MuiStepIcon-root.MuiStepIcon-completed {
  color: $sprayBlue !important;
} */
.MuiCheckbox-colorPrimary.Mui-checked {
	color: $azureBlue !important;
	background-color: transparent !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
	color: $azureBlue !important;
	background-color: transparent !important;
}

// .MuiButton-containedPrimary {
//   background-color: $azureBlue !important;
// }
.MuiTooltip-popper {
	z-index: 1300 !important;
}
.MuiFormControlLabel-label {
	white-space: pre-wrap !important;
}
.material-field2 .MuiSlider-root {
	color: $azureBlue !important;
}

.MuiSlider-root {
	span {
		pointer-events: none !important;
	}
}
.MuiButton-label {
	pointer-events: none !important;
}
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.MuiAlert-root {
	width: 100%;
}

.field-container {
	padding-top: 0.5em;
}

.paper-container {
	background: #fff;
	width: 100%;
	padding: 2em;
}
.flex-center {
	display: flex;
	justify-content: center;
}
.paper-container-review {
	background: #fff;
	width: 100%;
	padding: 0.5em;
	overflow-y: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.Mui-error {
	color: red;
}

.user-logo {
	max-height: 200px;
	max-width: 200px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.imageUpload {
	display: none;
}

.imageUploadContainer {
	width: 210px !important;
	height: 210px !important;
	border: 2px solid grey;
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 8px;
	border-color: rgba(0, 0, 0, 0.23);
}

.logoContainer {
	width: 150px !important;
	height: 150px !important;
	margin: auto;
	border: 2px solid grey;
	border-radius: 8px;
	border-color: rgba(0, 0, 0, 0.23);
}

#foreigntrade {
	transform: translate(-125%, -50%);
}

/* 
.MuiContainer-maxWidthLg {
  max-width: 1400px !important;
} */

.jYMKxK {
	bottom: 0px !important;
}
.kuzeVa {
	padding: 5px !important;
}

.industry-exp-guide {
	list-style-type: none;
	color: $catalinaBlue;
	font-size: 1em;
	text-align: justify;
	margin-top: 0.3em;
	margin-bottom: 1em;
}
.floating-button-container {
	position: fixed;
	bottom: 0;
	font-size: 1.6em;
	z-index: 9999;
}
//REFACTOR BUTTON STYLES
.button-block {
	text-transform: none !important;
	height: 6.5em;
	width: 10em;
	margin: 1em;
}
.button-general {
	background: $azureBlue !important;
	span {
		text-transform: none !important;
		color: $pureWhite;
		font-size: 1.2em;
	}
}
.button-provider-request {
	background: $azureGradient;
	border-radius: 0.1em;
	width: 100%;
	height: 3.1em;
	cursor: pointer;
	span {
		color: $pureWhite;
		text-transform: none;
		font-size: 1.3em;
		font-weight: 600;
	}
}
.button-provider-request:hover {
	background: $sprayGradient;
	transition: 0.3s;
}

.button-provider-profile-blue {
	background: $azureGradient;
	border-radius: 0.1em;
	height: 4rem;
	width: 17.7rem;
	margin-bottom: 0.4em !important;
	margin-top: 0.4em !important;
	cursor: pointer;
	span {
		font-size: 1.1em;
		font-weight: 600;
		color: $pureWhite;
		text-transform: none;
	}
}
.button-req-url {
	text-align: center;
	background: $sprayGradient;
	border-radius: 0.3em;
	height: 100%;
	width: 100%;
	cursor: pointer;
	a {
		padding: 0.5em;
		width: 100%;
		height: 100%;
		display: inline-block;
		font-size: 1.1em;
		font-weight: 500;
		color: $pureWhite;
	}
}
.button-req-url:hover {
	background: $azureGradient;
	transition: 0.3s;
}

.button-provider-profile-blue:hover {
	background: $sprayGradient;
	color: $pureWhite;
	transition: 0.3s;
	border: 2px solid $azureBlue;
}

.button-provider-profile-white {
	background: $pureWhite;
	border-radius: 0.1em;
	border: 2px solid $azureBlue !important;
	height: 4rem;
	width: 17.7rem;
	margin-bottom: 0.4em;
	cursor: pointer;
	span {
		font-size: 1.1em;
		font-weight: 600;
		color: $azureBlue;
		text-transform: none;
	}
}
.button-provider-profile-white:hover {
	background: $whiteGradient;
	color: $azureBlue;
	transition: 0.3s;
}

.button-subscription {
	border: 1px solid rgb(161, 160, 160);
	width: 22.7em;
	background: $castilWhite;
	p {
		color: grey;
	}
}

.button-subscription:hover {
	background: $lightBlue;
	color: $pureWhite;
	transition: 0.5s;
	border: 1px solid $catalinaBlue;
	p {
		color: $catalinaBlue;
	}
}
.selectedPackage {
	border: 5px solid #093c77 !important;
	background: $sprayBlue;
	p {
		color: $catalinaBlue;
	}
}
.selectedPackage:hover {
	border: 5px solid #093c77 !important;
	background: $sprayBlue;
}
.button-register {
	margin-left: 0.5em;
	padding: 1em 1.7em;
	border-radius: 0.1em;
	border: 1px solid $sprayBlue !important;
	width: 18.5em;
	height: 3.1em;
	cursor: pointer;
	span {
		font-size: 1.1em;
		font-weight: 700;
		text-transform: none;
		color: $sprayBlue;
	}
}
.button-register:hover {
	background: $sprayBlue !important;
	transition: 0.3s;
	cursor: pointer;
	span {
		color: $pureWhite;
	}
}
.button-login {
	margin-left: 0.5em;
	padding: 1em 1.7em;
	border-radius: 0.1em;
	border: 1px solid $pureWhite !important;
	background: $sprayBlue !important;
	width: 11.5em;
	height: 3.1em;
	cursor: pointer;
	span {
		font-size: 1.1em;
		font-weight: 700;
		text-transform: none;
		color: $catalinaBlue;
	}
}
.button-login:hover {
	background: $pureWhite !important;
	transition: 0.3s;
	cursor: pointer;
}

.button-seablue {
	background: $seaGradient;
	width: 100%;
	height: 5em;
	cursor: pointer;
	span {
		color: $pureWhite;
		font-weight: bold;
		font-size: 1.2em;
		text-transform: none;
		line-height: normal;
	}
}
.button-seablue:hover {
	background: $reverseseaGradient;
}
.button-disabled {
	background: $lightGrey !important;
	border: 2px solid $azureBlue;
	width: 100%;
	height: 5em;
	span {
		color: $darkGrey;
		font-weight: bold;
		font-size: 1.2em;
		text-transform: none;
		line-height: normal;
	}
}
.button-azure {
	background: $azureGradient;
	border: 2px solid $azureBlue;
	width: 100%;
	height: 5em;
	cursor: pointer;
	span {
		color: $pureWhite;
		font-weight: bold;
		font-size: 1.2em;
		text-transform: none;
		line-height: normal;
	}
}

.button-azure:hover {
	background: $sprayGradient;
	transition: 0.3s;
}
.button-spray-blue {
	background: $blueGradient;
	width: 100%;
	height: 5em;
	cursor: pointer;
	span {
		font-weight: bold;
		color: $pureWhite;
		font-size: 1.2em;
		text-transform: none;
		line-height: normal;
	}
}
.button-spray-blue:hover {
	background: $reverseblueGradient;
}
.button-white-azureborder {
	background: $pureWhite;
	border: 2px solid $azureBlue !important;
	width: 100%;
	height: 5em;
	cursor: pointer;
	span {
		font-weight: bold;
		color: $azureBlue;
		font-size: 1.2em;
		text-transform: none;
	}
}
.button-white-greenborder {
	background: $pureWhite;
	border: 2px solid rgb(89, 190, 89) !important;
	width: 100%;
	height: 5em;
	cursor: pointer;
	span {
		font-weight: bold;
		color: rgb(89, 190, 89);
		font-size: 1.2em;
		text-transform: none;
	}
}
.button-white-azureborder:hover {
	background: $whiteGradient;
	transition: 0.3s;
}
.table-button {
	background: $azureGradient;
	border-radius: 0.1em !important;
	border: 2px solid $azureBlue;
	opacity: 1;
	color: #ffffff !important;
	width: 100%;
	text-transform: none !important;
	cursor: pointer;
	span {
		color: white !important;
	}
}
.table-button:hover {
	background: $sprayGradient;
	transition: 0.3s;
}
.white {
	background: $castilWhite !important;
	color: $darkGrey !important;
	border: 1px solid $lightGrey !important;
	span {
		color: $azureBlue !important;
	}
}
.white:hover {
	background: $whiteGradient !important;
	transition: 0.3s;
}
.table-button:disabled {
	background: $darkGrey;
	color: #000;
	span {
		line-height: normal !important;
	}
}
.selected-payment {
	/* border: 5px solid $castilWhite !important; */
	opacity: 1 !important;
}
.button-payment {
	background: $azureGradient;
	opacity: 0.6;
	border-radius: 0.3em !important;
	margin: 1em;
	font-weight: 400 !important;
	width: 35em;
	height: 11em;
	padding: 1.5em 2em;
	border: 1px solid $azureBlue;
	text-transform: none !important;
	p {
		color: white !important;
		margin: 0 !important;
	}
}
.button-payment:hover {
	background: $sprayGradient;
	opacity: 1 !important;
	color: $pureWhite;
	transition: 0.5s;
	border: 1px solid $azureBlue;
}
.button-proceed-card {
	background: $azureGradient;
	border-radius: 0.6em !important;
	margin: 1em;
	font-weight: 400 !important;
	width: 25em;
	/* height: 11em; */
	padding: 1.5em 2em;
	border: 1px solid $azureBlue;
	text-transform: none !important;
	p {
		color: white !important;
		margin: 0 !important;
	}
}
.button-proceed-card:hover {
	background: $sprayGradient;
	color: $pureWhite;
	transition: 0.5s;
	border: 1px solid $azureBlue;
}
.provider-card-floating-button {
	background: $azureBlue !important;
	color: $pureWhite !important;
	text-transform: none !important;
	font-size: 0.6em !important;
	width: 20em !important;
}
.show-contact-button {
	background: $azureBlue !important;
	color: $pureWhite !important;
	text-transform: none !important;
	font-size: 0.7em !important;
	margin-top: 1em !important;
	margin-bottom: -0.5em !important;
	width: 19em !important;
	height: 2.3em !important;
	span {
		font-size: 1.3em;
	}
	@media (max-width: 600px) {
		margin-top: 0.25em !important;
		margin-bottom: 0.25em !important;
		height: 1.8em !important;
		span {
			font-size: 1em;
		}
	}
}
@media (max-width: 870px) {
	.button-login {
		border: 1px solid $sprayBlue !important;
		background: $pureWhite !important;
		margin-top: 0.4em !important;
		span {
			color: $sprayBlue;
		}
	}
	.button-login:hover {
		background: $sprayBlue !important;
		span {
			color: $pureWhite;
		}
	}
}
.search-popup-button {
	background: $catalinaBlue !important;
	text-transform: none !important;
	span {
		color: $pureWhite;
		padding: 0.3em;
		font-size: 1.4em;
	}
}
//TEXT STYLES
.sub-title-small-bold {
	color: $catalinaBlue;
	font-size: 1em;
	font-weight: 600;
	letter-spacing: 0;
	margin: 0.2em;
}
.sub-title-normal {
	color: $catalinaBlue;
	font-size: 2em;
	letter-spacing: 0;
	margin-top: 0em;
}
.sub-title {
	color: $catalinaBlue;
	font-size: 1.4em;
	letter-spacing: 0;
	margin-top: 0.3em;
	margin-left: 0.2em;
	margin-bottom: 0.2em;
	padding-bottom: 0.15em;
	border-bottom: 0.1rem solid rgba(51, 97, 152, 0.1);
}
.large-text {
	color: $catalinaBlue;
	font-size: 1.4em;
	margin-left: 0.2em;
	margin-right: 0.3em;
	margin-bottom: 0;
	margin-top: 0;
	letter-spacing: 0;
	float: left;
}
.large-text-bold {
	color: $catalinaBlue;
	font-size: 1.2em;
	font-weight: bold;
}
.medium-text {
	font-size: 1em;
	color: $catalinaBlue;
}
.medium-text-bold {
	color: $catalinaBlue;
	font-size: 1em;
	font-weight: bold;
}
.small-text {
	font-size: 0.85em;
	color: $catalinaBlue;
}
.small-text-black {
	font-size: 0.8em;
	width: 95%;
}
.diagonal-container {
	width: 100%;
	height: 100%;
	border-bottom: 250px solid $catalinaBlue;
	border-left: 100px solid transparent;
}

.title-container-colored {
	color: $catalinaBlue;
	background-color: $castilWhite;
	width: 100%;
	/*   padding: 0.35em;
  margin-top: 0.15em;
  margin-bottom: 0.15em; */
	text-align: left;
	letter-spacing: 0;
}
.h1-icon-description {
	color: $catalinaBlue;
	background-color: $castilWhite;
	width: 100%;
	padding: 0.35em;
	margin-top: 0.15em;
	margin-bottom: 0.15em;
	text-align: left;
	letter-spacing: 0;
}
.box-shadow {
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(129, 30, 30, 0.24) !important;
}
.h1-icon-description-normal {
	color: $catalinaBlue;
	width: 100%;
	padding: 0.35em;
	margin-top: 0.15em;
	margin-bottom: 0.15em;
	text-align: left;
	letter-spacing: 0;
}
//ICONS AND IMAGES
.Icon16 {
	width: 16px;
	height: 16px;
}
.Icon32 {
	width: 32px;
	height: 32px;
}
.Icon64 {
	width: 64px;
	height: 64px;
}
.table-icon {
	margin-right: 2em;
}
.title-icon {
	margin-left: 0.2em;
	margin-right: 0.2em;
	margin-bottom: -0.2em;
	@media (max-width: 600px) {
		height: 1.3em;
		width: 1.2em;
	}
}
.title-icon-profile {
	position: relative;
	margin-left: 0.2em;
	margin-right: 0.5em;
	top: 20%;
}
.header-dropdown-icon {
	height: 1em;
	width: 1em;
	margin-left: 0.2em;
	margin-bottom: -0.2em;
}
.info-icon {
	height: 0.8em;
	width: 0.8em;
}
.payment-image {
	height: 5.7em;
	width: 8em;
}
.search-input-icon {
	height: 2em;
	width: 2em;
	margin-top: 0.8em;
	margin-right: 0.5em;
	color: black;
	pointer-events: none !important;
}
.checkbox-label-liability {
	color: $catalinaBlue;
	align-self: flex-start !important;
	margin-left: 0.2em !important;
	margin-top: -0.5em !important;
}
.checkbox-label,
.slider-label {
	color: $catalinaBlue;
}
.checkbox-label-small {
	color: $catalinaBlue;
	span {
		font-size: 0.75em;
	}
}

//DESCRIPTION INPUT FIELD IN PROVIDER INFO
/* #description {
  height: 2.36em !important;
} */
/* @media (min-width: 601px) {
  .avatar-text {
    font-size: 1.1em;
    color: black;
    @media (max-width: 1110px) {
      font-size: 0.9em !important;
    }
    @media (max-width: 940px) {
      font-size: 0.65em !important;
    }
  }
  .MuiStepLabel-root {
    flex-direction: row !important;
  }
  .MuiStepLabel-label.MuiStepLabel-active {
    a {
      font-weight: 800;
      font-size: 1.05em;
    }
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin: 0.2em !important;
    margin-bottom: 0.3em !important;
    font-size: 0.95em !important;
    color: $pureWhite !important;
    @media (max-width: 1110px) {
      font-size: 0.8em !important;
    }
    @media (max-width: 979px) {
      font-size: 0.65em !important;
    }
    @media (max-width: 880px) {
      font-size: 0.6em !important;
    }
    @media (max-width: 845px) {
      font-size: 0.5em !important;
    }
  } 
.MuiStepper-root {
  padding: 0.2em !important;
}

  .MuiStepIcon-root {
    margin: 0.2em !important;
    color: $lightGrey !important;
  }
  .MuiStepIcon-root.MuiStepIcon-active {
    color: $pureWhite !important;
    border: 1px solid $catalinaBlue !important;
    border-radius: 13px;
  }
  .MuiStepIcon-active.MuiStepIcon-completed {
    color: $sprayBlue !important;
  }
  .MuiStepIcon-text {
    fill: $catalinaBlue !important;
    font-weight: 1000;
    @media (max-width: 880px) {
      font-size: 0.4em !important;
    }
  }
  .MuiStepConnector-lineVertical {
    border-left-style: none !important;
  }
  .MuiStepLabel-labelContainer {
    width: 100%;
    a {
      color: $pureWhite !important;
    }
  }
} */
@media (max-width: 800px) {
	.small-text {
		font-size: 0.65em;
	}
}
@media (max-width: 600px) {
	.MuiMobileStepper-progress {
		margin-right: -0.5em !important;
	}
	.MuiStepIcon-root.MuiStepIcon-active {
		color: $azureBlue !important;
	}
	.MuiStepLabel-root {
		flex-direction: row !important;
	}
	.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
		margin: 0 !important;
		text-align: center;
		font-size: 0.9em !important;
	}
	.MuiStepper-root {
		padding: 0 !important;
	}
	.MuiSvgIcon-root {
		margin: 0.2em !important;
	}
	.MuiStepConnector-lineVertical {
		border-left-style: none !important;
	}
	.h1-icon-description {
		font-size: 1.4em;
	}
	.sub-title {
		font-size: 0.9em !important;
	}
	.medium-text-bold {
		font-size: 0.8em;
	}
	.large-text-bold {
		font-size: 1.2em;
	}
	.toggle-switch-container {
		padding-left: 2.45em;
	}
	#content-container {
		padding: 0 !important;
		height: 100vh;
	}
	.paper-container {
		padding: 0.5em !important;
	}
}
//NEW STYLES
//TEXT
.Underlined {
	text-decoration: underline;
}
.GDPRLink {
	color: #3797f0;
	text-decoration: none;
	font-size: 14px;
	line-height: 22px;
}
.ErrorText {
	font-size: 14px !important;
	font-weight: bold !important;
	color: red !important;
	text-align: left !important;
}
.MediumBabyBlueBold {
	color: $babyBlue;
	font-weight: bold;
	font-size: 18px;
}
.TextCenter {
	text-align: center;
}
.BabyBlue {
	color: $babyBlue;
}
.Primary {
	color: $strongBlue !important;
}
.SecondaryBlue {
	color: $secondaryBlue !important;
	p {
		color: $secondaryBlue !important;
	}
}
.StandardText {
	font-size: 14px;
	line-height: 22px;
	text-decoration: none;
}
.StandardText18 {
	font-size: 18px !important;
	line-height: 22px;
	text-decoration: none;
	margin: 12px 0 !important;
}
.StandardTextBold {
	font-size: 18px;
	line-height: 34px;
	text-decoration: none;
	font-weight: 600;
}
//CONTAINERS
.MuiDialog-paperWidthSm {
	max-width: 1400px !important;
	overflow-x: hidden !important;
}
.SpaceAround {
	display: flex;
	flex-direction: row !important;
	justify-content: space-around;
}
.SpaceBetween {
	display: flex;
	flex-direction: row !important;
	justify-content: space-between;
}
.FlexCenter {
	display: flex;
	align-items: center;
}
.FlexFullCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}
.Column {
	flex-direction: column;
}
.FullWidth {
	width: 100% !important;
}
.Width60 {
	width: 60% !important;
}
.HalfWidth {
	width: 50%;
}
.Padding8 {
	padding: 8px;
}
.Margin0 {
	margin: 0;
}
.Margin8 {
	margin: 8px;
}
.MarginHorizontal {
	margin: 16px 0px;
}
.MarginH12 {
	margin: 12px 0px;
}
.MarginH24 {
	margin: 24px 0px;
}
.MarginH40 {
	margin: 60px 0px;
}
.MarginH60 {
	margin: 60px 0px;
}
.MarginVertical {
	margin: 0px 6px;
}
.MarginRight16 {
	margin-right: 16px;
}
.MarginBottom40 {
	margin-bottom: 40px;
}

.MarginBottom80 {
	margin-bottom: 80px;
}

.Flex1 {
	flex: 1;
}
.Bold {
	font-weight: bold !important;
}
.BoxShadow {
	box-shadow: none !important;
}
.DisplayNone {
	display: none !important;
}
.Hidden {
	visibility: hidden !important;
}
.LineThrough {
	text-decoration: line-through;
}
//NEW BUTTONS
.RoundBtn {
	border-radius: 30px !important;
	height: 44px;
	span {
		text-transform: none !important;
		padding: 12px 16px;
		font-size: 16px !important;
	}
	@media (max-width: 600px) {
		height: 35px;
		span {
			font-size: 14px !important;
		}
	}
}
.SquareBtn {
	height: 44px;
	span,
	p {
		text-transform: none;
		font-size: 18px !important;
	}
	@media (max-width: 600px) {
		height: 35px;
		span {
			font-size: 14px !important;
		}
		p {
			font-size: 14px !important;
		}
	}
}

.disabledReqBtn {
	color: #50558180 !important;
	background-color: lightgrey !important;
	box-shadow: none !important;
	border: none !important;
}

.WhiteBlackBtn {
	font-family: 'Satoshi', sans-serif !important;
	height: 44px;
	background-color: white !important;
	span {
		font-family: 'Satoshi', sans-serif !important;
		color: black !important;
		text-transform: none;
		font-size: 18px !important;
		font-weight: 600;
	}
	@media (max-width: 600px) {
		height: 35px;
		span {
			font-size: 14px !important;
		}
	}
}
.BlueBorder {
	border: 1px solid $strongBlue !important;
}
.Round {
	border-radius: 25px !important;
}

//SIZES

.Medium {
	width: 125px;
}
.Long {
	width: 220px;
}
.ExtraLong {
	width: 260px;
}
.XXLong {
	width: 324px;
}
//AUTO MARGIN
.AutoMarginTop {
	margin-top: auto;
}
.AutoMarginLeft {
	margin-left: auto;
}
.AutoMarginBot {
	margin-bottom: auto;
}
.AutoMarginRight {
	margin-top: auto;
}
//NEW MUI GLOBAL STYLE
.MuiInputBase-root {
	background-color: white;
	font-family: 'Satoshi, sans-serif';
}
.MuiTextField-root {
	width: 100%;
	columns: 2;
	font-family: 'Satoshi, sans-serif';
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
	border-radius: 20px !important;
	font-family: 'Satoshi, sans-serif';
	box-shadow: none !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
	font-family: 'Satoshi', sans-serif !important;
}
//PROVIDER STEPPER
.MuiMobileStepper-progress {
	width: 100% !important;
	height: 8px !important;
	border-radius: 50px !important;
}

.MuiLinearProgress-barColorPrimary {
	/*   background-color: #0097a7 !important; */
	background: linear-gradient(
			0deg,
			rgba(33, 150, 83, 0.4) 0%,
			rgba(255, 255, 255, 0.4) 47.4%,
			rgba(33, 150, 83, 0.4) 100%
		),
		linear-gradient(90deg, #219653 0%, #71e0a0 100%) !important;
}

.MuiLinearProgress-colorPrimary {
	background-color: #f2f2f2 !important;
}
.WS {
	@media (max-width: 600px) {
		display: none !important;
	}
}

.Mobile {
	@media (min-width: 600px) {
		display: none !important;
	}
}
