.MuiContainer-root {
	display: block !important;
}

.dynamicListPages {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	height: 100%;
	width: 95%;
	max-width: 1370px;
	padding-top: 24px;
}

.dlpHeader {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 10px 0;
	// border: 1px solid #e3e5e9;
	border-radius: 18px;
	padding: 16px;
}

.dlpHeaderText {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.dlpHeaderTextUp {
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	color: #1c32f3;
	padding: 4px 0px 4px 0px;
}

.dlpHeaderTextDown {
	display: flex;
    align-items: center;
    justify-content: space-between;
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	padding: 6px 0px 6px 0px;
}

.dlpAccountantheader {
	width: 80px;
	flex-shrink: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-32%) translateX(-10%);
    z-index: 10;
}

.dlpDividerMain {
	background-color: #e3e5e9;
	height: 1px;
	margin-bottom: 8px;
}

.dlpContent {
	display: flex;
	width: 100%;
	height: 100%;
	margin: 10px 0px;
}

.dlpContentLeft {
	width: 25%;
	min-width: 200px;
	box-sizing: border-box;
	padding: 0px 16px 0 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	margin-bottom: 8px;
}

.dlpFilterTitleContainer {
	display: block;
	width: 100%;
	margin-bottom: 18px;
}

.dlpFilterTitle {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	font-weight: 600;
	margin-bottom: 8px;
}

.dlpFilterSubTitle {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	font-size: 14px;
	font-weight: 500;
	color: #505581;
	margin-bottom: 16px;
}

.dlpFilterTitleIcon {
	width: 16px;
	margin-right: 8px;
}

.dlpFilterTitleText {
	margin-left: 8px;
}

.dlpSearchContainer {
	border: 1.6px solid #e3e5e9;
	border-radius: 12px;
	padding: 16px;
	width: 100%;
}

.dlpSearchCityContainer {
	width: 100%;
}

.dlpSearchCityTitle {
	font-weight: 600;
}

.dlpSearchCityCheckboxContainer {
	display: flex;
	align-items: center;
}

.dlpSearchCityCheckbox {
	padding: 2px 4px 2px 0px;
}

.dlpDynamicListPagesDivider {
	width: 100%;
	background-color: #e3e5e9;
	height: 1px;
	margin: 12px 0px;
}

.dlpSearchIndustryContainer {
	width: 100%;
}

.dlpSearchIndustryTitle {
	font-weight: 600;
}

.dlpSearchIndustryCheckboxContainer {
	display: flex;
	align-items: center;
}

.dlpSearchIndustryCheckbox {
	padding: 2px 4px 2px 0px;
}

.dlpSearchServicesContainer {
	width: 100%;
}

.dlpSearchServicesTitle {
	font-weight: 600;
	margin-bottom: 8px;
}

.dlpSearchServicesCheckboxContainer {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	color: rgba(11, 15, 49, 1);
}

.dlpSearchservicesCheckbox {
	padding: 2px 4px 2px 0px;
}

.dlpSearchOfficeSizeCityContainer {
	width: 100%;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	color: rgba(11, 15, 49, 1);
}

.dlpSearchOfficeSizeTitle {
	font-weight: 600;
}

.dlpSearchDigitalBadgeContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.dlpSearchDigitalBadgeTitle {
	font-weight: 600;
}

.dlpNewlyFoundedContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.dlpNewlyFoundedTitle {
	font-weight: 600;
}

.dlpSearchSwitchIcon {
	margin: 0px 0px 0px 8px;
}

.css-j204z7-MuiFormControlLabel-root {
	margin-right: 0px;
}

.dlpSearchButton {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 14px 0px;
	width: 100%;

	.dlpSearchButtonCustom {
		background-color: #fdb400;
		color: black;
		border-radius: 8px;
		padding: 10px 20px;
		font-size: 0.9rem;
		font-weight: 600;
		text-transform: none;
		box-shadow: none;
		width: 100%;
		letter-spacing: normal;

		&:hover {
			background-color: #f5ca61;
		}

		&:active,
		&:focus {
			background-color: #fdb400;
		}
	}
}

.dlpSetDefaultStateButton {
	font-size: 14px;
	color: #505581;
}

.dlpContentRight {
	width: 75%;
	margin-left: 24px;
	border-radius: 12px;
	box-sizing: border-box;
	height: 100%;
}

.dlpAllProvidersContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.dlpAllProvidersNumber {
	font-weight: 600;
	margin-bottom: 8px;
}

.dlpAllProvidersSorting {
	font-size: 14px;
	color: #505581;
}

.dlpAllProvidersSubTitle {
	font-size: 14px;
	font-weight: 500;
	color: #505581;
	margin: 0px 0px;
}

.dlpAllProviderCards {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 12px;
	justify-items: stretch;
}

.dlpShortList {
	margin-top: 16px;
	height: 170px;
	overflow-x: scroll;
}

.dlpLongList {
	margin-top: 16px;
	height: 400px;
	overflow-y: scroll;
}

.dlpExpandText {
	color: #1c32f3;
	text-decoration: underline;
	cursor: pointer;
}

.dlpFilterDialog {
	display: flex;
	flex-direction: column;
	justify-content: center;
	//height: 650px;
	min-width: 350px;
	width: 100%;

	padding: 18px;
}

.dlpClosingX {
	margin-left: auto;
	margin-right: 0;
	cursor: pointer;
}

.dlpApart {
	display: flex;
	justify-content: space-between;
}

// .dlpAllProviderCard {
//     padding: 12px 18px;
//     margin-bottom: 16px;
//     border: 1.6px solid #E3E5E9;
//     border-radius: 12px;
// }

// .dlpAllProviderCardHeader {
//     display: flex;
// }

// .dlpAllProviderImageDefault {
//     width: 42px;
//     position: relative;
// }

// .dlpAllProviderCardNameContainer {
//     display: flex;
//     align-items: center;
// }

// .dlpAllProviderCardName {
//     font-weight: 600;

// }

// .dlpAllProviderAvgContainer {
//     display: "flex";
//     justify-content: center;
//     align-items: center;

//     .dlpAllProviderAvgStars {
//         display: flex;
//         align-items: center;
//     }

//     .dlpAllProviderAvgNumber {
//         color: #505581;
//         font-size: 0.8rem;
//         margin-left: 6px;
//     }

//     .dlpAllProviderReviewsNumber {
//         color: #505581;
//         font-size: 0.8rem;
//         margin-left: 6px;
//     }
// }

// .dlpAllProviderCompanySize {
//     font-size: 0.8rem;
//     font-weight: 500;
//     color: #505581;
// }

// .dlpAllProviderInsurance {
//     font-size: 0.8rem;
//     font-weight: 500;
//     color: #505581;
// }

// .dlpAllProvidersRFPButton {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding: 14px 0px;
//     width: 112px;

//     .dlpAllProvidersRFPButtonCustom {
//         background-color: #FDB400;
//         color: black;
//         border-radius: 8px;
//         padding: 10px 20px;
//         font-size: 0.9rem;
//         font-weight: 600;
//         text-transform: none;
//         box-shadow: none;
//         width: 100%;
//         letter-spacing: normal;

//         &:hover {
//             background-color: #F5CA61;
//         }

//         &:active,
//         &:focus {
//             background-color: #FDB400;
//         }
//     }
// }

.dlpProposalContainer {
	display: flex;
	background-color: #f3f4fe;
	border-radius: 12px;
	margin: 12px 0;
}

.dlpProposalImage {
	display: flex;
	width: 60px;
	border-radius: 12px;
}

.dlpProposalImage img {
	display: flex;
	width: 60px;
	margin-right: 8px;
	border-radius: 12px;
}

.dlpProposalTextContainer {
	padding: 8px 0px 16px 0px;
}

.dlpProposalText1 {
	padding: 8px 8px 0px 12px;
	font-weight: 600;
}

.dlpProposalText2 {
	padding: 8px 8px 0px 12px;
	color: #505581;
}

.dlpProposalButton {
	//position: relative;
	display: flex;
	margin-left: auto;
	margin-right: 14px;
	//flex-direction: column;
	align-items: center;
	padding: 14px 0px;
	width: fit-content;

	.dlpProposalButtonCustom {
		background-color: #1c32f3;
		color: white;
		border-radius: 8px;
		padding: 10px 20px;
		font-size: 0.9rem;
		text-transform: none;
		box-shadow: none;
		width: 220px;
		letter-spacing: normal;

		&:hover {
			background-color: #4858ed;
		}

		&:active,
		&:focus {
			background-color: #4858ed;
		}
	}
}

.dlpProvidersPaginator {
	display: flex;
	justify-content: center;
	margin: 16px 0px;
}

.dlpSearchOnMobileContainer {
	display: flex;
	align-items: center;
}

.dlpSearchOnMobile {
	display: none;
}

.dlpSearchOnMobileIcon {
	width: 14px;
	margin-left: 4px;
}

.dlpWrap {
	display: flex;
	flex-wrap: wrap;
	gap: 2px;
}

.dlpServicesConatiner {
	// background-color: #f6f7fe;
	// border: 1px solid #e3e5e9;
	width: 100%;
	// border: 1px solid #6a77f7;
	border-radius: 12px;
	padding: 16px;
}

.dlpServicesOptionContainerTitle {
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 16px;
}

.dlpServiceOptionWrapper {
	display: flex;
	justify-content: space-between;
	gap: 32px;
}

.dlpServiceOptionTitle {
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 8px;
}

.dlpServiceOption {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-width: 0;
	border: 1px solid #e3e5e9;
	border-radius: 12px;
	padding: 16px;
	background: #F3F4FE;
	transition: background-color 0.3s ease, transform 0.2s ease;
	cursor: pointer;
}

.dlpServiceOption:hover {
	transform: scale(1.02);
}

.dlpServiceOptionButtonWrapper {
	display: flex;
	justify-content: space-between;
	// align-items: center;
}

.dlpServiceOptionPrice {
	display: flex;
	font-size: 12px;
	font-weight: 700;
	margin-bottom: 8px;
	text-align: center;
	white-space: nowrap;
}

@media only screen and (max-width: 600px) {
	.dynamicListPages {
		padding: 0px;
	}

	.dlpHeader {
		padding: 0px 12px;
		border: none;
		margin-bottom: 0px;
	}

	.dlpContent {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: auto;
		margin: 10px 0px;
		padding: 0px 12px;
	}

	.dlpFilterTitleContainer {
		display: none;
	}

	.dlpContentLeft,
	.dlpContentRight {
		width: 100%;
		padding: 0px;
		margin: 10px 0;
	}

	.footer {
		width: 100%;
		margin-top: 20px;
	}

	.dlpSearchOnMobile {
		display: block;
		width: 30vw;
		background-color: #1c32f3;
		color: white;
		font-size: 14px;
		text-transform: none;
		padding: 10px 20px;
		border-radius: 5px;
		border: none;
		cursor: pointer;

		&:hover {
			background-color: #1c32f3;
		}

		&:active {
			background-color: #003399;
		}

		&:focus {
			outline: none;
		}
	}

	.dlpFilterIconWhite {
		margin-left: 4px;
	}

	.dlpFilterDialog {
		display: flex;
		flex-direction: column;
		justify-content: center;
		//height: 650px;
		min-width: 300px;
		width: 100%;

		padding: 18px;
	}

	.dlpAllProviderCards {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 12px;
		justify-items: stretch;
	}

	.dlpProposalTextContainer {
		padding: 0px;
	}

	.dlpProposalContainer {
		display: flex;
		background-color: #f3f4fe;
		border-radius: 12px;
		margin: 12px 0;
	}

	.dlpProposalImage {
		display: flex;
		width: 60px;
		border-radius: 12px;
		align-items: flex-end;
	}

	.dlpProposalImage img {
		display: flex;
		width: 60px;
		margin-right: 8px;
		border-radius: 12px;
	}

	.dlpProposalText1 {
		font-size: 14px;
		padding: 16px 12px 0px 12px;
		font-weight: 600;
	}

	.dlpProposalText2 {
		font-size: 14px;
		padding: 12px;
		color: #505581;
	}

	.dlpProposalButton {
		display: none;
	}

	.dlpProposalButtonMobile {
		//position: relative;
		display: flex;
		//flex-direction: column;
		align-items: center;
		padding: 0px 12px 0px 0px;
		justify-content: space-between;

		.dlpProposalButtonCustomMobile {
			background-color: #1c32f3;
			color: white;
			border-radius: 8px;
			padding: 10px 20px;
			font-size: 14px;
			text-transform: none;
			box-shadow: none;
			//width: 220px;
			letter-spacing: normal;

			&:hover {
				background-color: #4858ed;
			}

			&:active,
			&:focus {
				background-color: #4858ed;
			}
		}
	}

	.dlpFilterToggleButtonMobile {
		//position: relative;
		display: flex;
		//flex-direction: column;
		align-items: center;
		padding: 0px 12px 0px 0px;
		justify-content: space-between;
		width: 100%;

		.dlpFilterToggleButtonCustomMobile {
			background-color: #1c32f3;
			color: white;
			border-radius: 8px;
			padding: 10px 20px;
			font-size: 14px;
			text-transform: none;
			box-shadow: none;
			//width: 220px;
			letter-spacing: normal;

			&:hover {
				background-color: #4858ed;
			}

			&:active,
			&:focus {
				background-color: #4858ed;
			}
		}
	}

	.dlpLongList {
		height: 70vh;
	}

	.dlpServicesConatiner {
		margin-top: 16px;
	}

	.dlpServiceOptionWrapper {
		flex-direction: column;
		gap: 16px;
	}

	.dlpAccountantheader {
		display: none;
	}
}