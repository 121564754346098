@import '../../shared/styles/variables.scss';

.ProvProfileContainer {
  width: 100%;
  padding: 0px 0px 32px 0px;
  box-shadow: 0px 4px 12px rgba(11, 63, 246, 0.4);
  border-radius: 20px;
  margin-bottom: 30px;
}
.ProvProfileContentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.ProvProfileBadgesContainer {
  position: relative;
  display: flex;
  justify-content: space-around;
  min-height: 50px;
  width: 100%;
  border-bottom: 2px solid $babyBlue;
  p {
    color: black;
    margin: 6px 0px;
  }
}
.ProvProfileContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}
.ProvProfileHeaderContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 220px;
  width: 100%;
  border-radius: 20px 20px 0 0;
  background: linear-gradient(
    278.21deg,
    rgba(153, 118, 181, 0.7) -6.75%,
    rgba(22, 0, 155, 0.7) 20.28%,
    rgba(1, 42, 188, 0.7) 52.23%,
    rgba(45, 70, 185, 0.7) 96.68%
  );
  /*  background: linear-gradient(278.21deg, #9976B5 -6.75%, #16009B 20.28%, #012ABC 52.23%, #2D46B9 96.68%); */
}
.ProvProfileAvatarContainer {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 16px 0px;
  h2 {
    width: 100%;
    font-size: 32px;
    color: white !important;
    font-weight: 500;
    margin: 3px 20px;
  }
  p {
    margin: 3px 8px;
    color: white;
  }
  span {
    color: white;
  }
}
.ProvProfileHeaderPrevious {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 225px;
  position: absolute;
  top: 35%;
  left: -3%;
}
.ProvProfileHeaderNext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 225px;
  position: absolute;
  position: absolute;
  top: 35%;
  right: -3%;
}
.ProvProfileCloseBtn {
  position: absolute;
  right: 2%;
  top: 4%;
}

.ProvProfileContactContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  h2 {
    width: 100%;
    font-size: 32px;
    color: white;
    font-weight: 500;
    margin: 3px 8px;
  }
  p {
    margin: 3px 8px;
    color: white;
  }
  span {
    margin: 0 18px;
    color: white;
  }
  a {
    margin: 0 18px;
    color: white;
    text-decoration: underline;
  }
}
.ProvProfileContact {
  background: white !important;
  border-radius: 30px !important;
  margin: 8px !important;
  span {
    color: $strongBlue;
    text-transform: none;
  }
}
.ProvProfileBadgesContainer {
  position: relative;
  display: flex;
  justify-content: space-around;
  min-height: 50px;
  margin: 15px 80px 20px 80px;
  border-bottom: 2px solid $babyBlue;
  p {
    color: black;
    margin: 6px 0px;
  }
}
.ProvProfileBackButton {
  span {
    color: $strongBlue !important;
    font-weight: 500;
    text-transform: none;
  }
}

.ProvProfileContentPaper {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(129, 30, 30, 0.1) !important;
  padding: 16px 32px;
  border-radius: 20px !important;
  width: 100%;
  line-height: 32px;
  margin: 16px 0px;
  h2 {
    color: $babyBlue;
    width: 100%;
    opacity: 0.6;
  }
  ul {
    list-style-position: outside;
  }
  li {
    color: $babyBlue;
    font-weight: 600;
    margin: 10px 0px;
    font-size: 18px;
  }
}
.ProvProfileServices {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.ProvDarkGreyText {
  p {
    text-align: center;
    color: $darkGrey;
    font-weight: 600;
    font-size: 20px;
    flex-basis: 30%;
    margin: 8px 0;
    display: flex;
    align-items: center;
  }
}
.ProvScoresContainer {
  display: flex;
}
.ProvScoresContentRight {
  padding: 8px;
  flex: 3;
}
.ProvScoresContentLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  h2 {
    width: 100%;
    color: #25d41fe1;
    font-weight: 600;
    opacity: 1;
    margin: 6px 0px;
  }
  p {
    margin: 6px 0px;
    font-size: 24px;
    font-weight: 400;
    color: #25d41fe1;
    text-shadow: 0px 0px 0 #969696, 0px 5px 5px #aba8a8;
  }
}

.ProvProfileDescription {
  h2 {
    width: 100%;
    color: black;
  }
  p {
    width: 70%;
  }
}
.ProvProfileReviewsContainer {
  width: 100%;
  h2 {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
  }
  p {
    font-size: 18px;
    color: black;
    font-weight: normal;
    text-align: left;
  }
}
.ProvReviewPaper {
  display: flex;
  border-radius: 20px !important;
  min-height: 180px !important;
  padding: 16px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0),
    0 1px 1px rgba(129, 30, 30, 0);
}

.ProvReviewHeadingContainer {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.ProvReviewText {
  width: 100%;
}
.ProvProfileScoresContainer {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}

.ProvProfileScore {
  flex: 50%;
  display: flex;
  align-items: center;
  padding: 0px 32px 0px 0px;
  p {
    color: black;
    font-size: 16px;
    font-weight: 500;
  }
}
.ProvProfileMatchScore {
  display: none;
}
.ProvProfileRating {
  display: none;
}
.ProvScoresMatchScore {
  display: none;
}
.ProvPriceLabel {
  margin: 32px;
}
.ProvPriceValue {
  font-size: 18px;
  font-weight: normal;
}
.ProvDescriptionText {
  color: black;
  font-size: 18px;
  text-align: left;
  font-weight: normal;
  margin-top: 32px;
}
.ProvQualificationsContainer {
  margin: 40px 0px;
  flex: 1;
  display: flex;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 600px) {
  .ProvProfileScoresContainer {
    width: 100%;
  }
  .ProvProfileContent {
    width: 100%;
  }
  .ProvProfileHeaderContainer {
    height: 320px;
  }
  .ProvProfileAvatarContainer {
    flex: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px 0;
    width: 100%;
    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      width: 100%;
    }
    p {
      line-height: 24px;
      width: 100%;
    }
    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .ProvProfileContactContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    span {
      margin: 3px 0;
    }
    a {
      margin: 3px 0;
    }
  }
  .ProvContactInfo {
    font-size: 14px;
  }
  .ProvProfileMatchScore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 100px;
    right: -15%;
    bottom: -4%;
    h2 {
      font-size: 16px;
      line-height: 7px;
      color: $greenHaze;
      font-weight: 600;
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 10px;
      font-weight: 500;
      line-height: 13px;
      color: black;
    }
  }
  .ProvScoresMatchScore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    position: absolute;
    height: 78px;
    width: 78px;
    border-radius: 100px;
    right: 0%;
    top: -8%;
    border: 2px solid $strongBlue;
    h2 {
      font-size: 21px;
      line-height: 9prov28px;
      color: $greenHaze !important;
      font-weight: 600;
      margin: 0;
    }
    p {
      margin: 6px 0px;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      color: black;
    }
  }
  .ProvProfileRating {
    display: block;
    span {
      color: gold !important;
    }
  }
  .ProvScoresContainer {
    ul,
    li {
      list-style-type: none;
      list-style-position: inside;
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
    span {
      color: black;
      font-weight: 600;
    }
  }
  .ProvScoresContentLeft {
    display: none;
  }
  .ProvProfileContentPaper {
    padding: 16px 8px;
    line-height: 32px;
    margin: 16px 0px;
    position: relative;
    width: 95%;
    h2 {
      color: $babyBlue;
      width: 100%;
      opacity: 1;
    }
  }
  .ProvPriceLabel {
    margin: 0;
    font-size: 16px;
    color: black !important;
  }
  .ProvPriceValue {
    font-size: 16px;
    color: $babyBlue !important;
    font-weight: 600 !important;
  }
  .ProvDescriptionText {
    color: black;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    margin-top: 16px;
    line-height: 24px;
  }
  .ProvQualificationsContainer {
    margin: 20px 0px;
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .ProvReviewHeadingContainer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    p {
      margin: 0;
      width: 100%;
    }
  }
  .ProvReviewPaper {
    box-shadow: none;
  }
}
