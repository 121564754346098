@import '../../../../shared/styles/variables.scss';

.SFProviderCardPaper {
  display: flex;
  flex-direction: column;
  // height: 280px;
  height: auto;
  // width: 394px !important;
  width: 100% !important;
  border: 1px solid #e3e5e9;
  border-radius: 6px !important;
  padding: 12px !important;

  cursor: pointer;
}
.SFProviderCardPaper:hover {
  background-color: rgba(55, 151, 240, 0.1) !important;
  //height: 241px !important;
}

.ProviderCardSection {
  display: flex;
  flex-direction: column;

  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #505581;
  }
}

.ProviderCardHeader {
  position: relative;
  height: 48px;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}

.ProvHeaderTexts {
  max-width: 95%;
  p {
    font-size: 16px;
  }
  span {
    font-size: 16px;
  }
}

.badgecontainer {
  height: 24px;
  background: rgba(28, 50, 243, 0.05);
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

/* .ProviderCardContainer {
  :hover {
    background-color: rgba(55, 151, 240, 0.1);
  }
} */

.shieldCheck {
  position: absolute;
  top: 0;
  right: 0;
}

.ScoreStyle {
  color: $babyBlue;
  min-height: 1.6rem;
}
.ContactInfo {
  color: $strongBlue;
  font-weight: 500;
}

.ProviderCardScores {
  display: flex;
  flex-direction: column;
  p {
    display: flex;
    justify-content: space-between;
    span {
      font-family: 'Satoshi';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #0b0f31;
    }
  }
}

@media (max-width: 600px) {
  .SFProviderCardPaper {
    flex-direction: column;
    // max-width: 343px;
    width: auto;
    // height: 278px;
    height: auto;
  }
  .ProviderCardSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    p {
      margin: 8px 0px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .ProviderCardSectionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    p {
      line-height: 16px;
      font-size: 14px;
    }
  }

  .ProviderCardHeader {
    width: 100%;
  }

  .ProviderCardScores {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }
  .ScoreStyle {
    font-weight: bold !important;
    color: $strongBlue;
    font-size: 14px !important;
  }

  .ProvHeaderTexts {
    p {
      font-size: 14px;
    }
    span {
      font-size: 14px;
    }
  }
}
