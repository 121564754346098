@import '../../shared/styles/variables.scss';

.DashboardContainer {
  display: flex;
  min-height: 900px;
  width: 100%;
  padding-top: 10px;
}

.DashboardNavigationContainer {
  flex: 1;
}

.DashboardContentContainer {
  margin-left: 1rem;
  padding: 40px;
  flex: 3;
  background-color: #f0f2fe;
}
.DashboardProfileInfo {
  display: none;
  flex: 1;
  padding-top: 120px;
}

.DashboardActiveLabel {
  font-size: 36px;
  color: $babyBlue;
  font-weight: 600;
  line-height: 44px;
  span {
    font-size: 14px;
    color: #303030;
    font-weight: 400;
    line-height: 26px;
  }
}

.ToRequests {
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 24px;
  p {
    margin: 10px 0;
    color: white;
  }
  img {
    margin: 0 12px;
  }
  svg {
    margin: 0 12px;
  }
  a {
    text-decoration: none;
  }
}
.ToRequests:hover {
  img {
    transition: all 250ms ease;
    margin-left: 24px !important;
  }
  svg {
    transition: all 250ms ease;
    margin-left: 24px !important;
  }
}

.ProviderInputContainerDashboard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: white;
  padding: 32px;
  border-radius: 20px;
  div {
    margin: 6px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .DashboardContainer {
    flex-direction: column;
    width: 100%;
    padding-top: 0px;
  }
}
