@import '../../../../shared/styles/variables.scss';
.ReqSentPaper {
  width: 450px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .grey {
    color: #000b16;
    font-family: 'Satoshi' sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
  }
  h2 {
    color: $azureBlue !important;
    font-weight: 700;
    width: 100%;
    font-size: 54px;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    span {
      color: $azureBlue;
      font-weight: 700;
      font-size: 18px;
    }
  }
}
@media (max-width: 600px) {
  .ReqSentPaper {
    width: 100%;
    padding: 12px;
    line-height: 26px;
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
    span {
      font-size: 16px;
    }
  }
}
