.vafFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.vafLabel {
  margin: 6px 0 0 3px;
  display: block;
  font-weight: 500;
  font-size: 0.9rem;
}

.vafForm {
  width: 100%;
  max-width: 580px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  margin: 24px;
  box-shadow: 0px 10px 20px 0px rgba(11, 15, 49, 0.1);
  position: relative;
  justify-content: center;
  border-radius: 16px 16px 16px 16px;
}

.vafFormContentHeader {
  position: relative;
  width: 100%;
  min-height: 120px;
  padding: 24px;
  border-radius: 16px 16px 0px 0px;
  background: linear-gradient(85.22deg, #0b1fcb 0%, #9747ff 92.28%);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #cccccc;
  border-bottom: none;
}

.vafTitle {
  font-size: 1.2rem;
  text-align: center;
  color: white;
}

.vafFormHeaderImg {
  width: 100px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.vafFormContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 580px;
  height: 60vh;
  gap: 6px;
  background-color: #ffffff;
  border: 2px solid #ccc;
  overflow: auto;
  margin: 0 auto;
  padding: 4px 12px;
  padding-bottom: 20px;
  position: relative;
}

.vafButtonContainer {
  position: relative;
  background-color: white;
  width: 100%;
  min-height: 60px;
  max-height: 100px;
  padding: 12px;
  border-radius: 0px 0px 16px 16px;
  display: flex;
  justify-content: space-between;
  gap: 100px;
  border: 2px solid #cccccc;
  border-top: none;
}

.vafSuccessMessage {
  position: relative;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}

.vafTextArea {
  width: 100%;
  align-self: center;

  .MuiInputBase-input {
    font-family: "Satoshi", sans-serif;
    color: black;
    font-size: 0.9rem;
  }
}

.vafTextField {
  width: 100%;
  align-self: center;

  .MuiInputBase-input {
    font-size: 0.9rem;
    font-weight: 400 !important;
  }
}

.vafSelectMenu {
  width: 100%;
  align-self: center;
  font-family: "Satoshi", sans-serif;
  color: black;

  .MuiInputBase-input {
    font-size: 0.9rem;
  }
}

.vafCheckboxContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
}

.vafFormControlLabel {
  width: 100%;
  margin: 2px 0;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: black;
}

.vafFormControlLabelChecked {
  width: 100%;
  margin: 2px 0;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  background-color: #e3f2fd;
  border-radius: 4px;
  border: 1px solid #1c32f3;
  color: #1c32f3;
}

.vafCheckbox {
  padding: 0;
  margin: 0;
}

.vafFormBtnMaxWidth {
  max-width: 110px;
}

.vafFormClosed {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  text-align: center;
}

.vafFormOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px 16px 16px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  color: white;
  text-align: center;
}

.vafFormOverlayMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  z-index: 6;
}

@media (max-width: 320px) {
  .vafForm {
    margin: 8px;
    width: 100vw;
  }

  .vafFormContent {
    height: 50vh;
  }

  .vafButtonContainer {
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .vafFormHeaderImg {
    display: none;
  }
}

@media (max-width: 480px) {
  .vafForm {
    margin: 12px;
    width: 100vw;
  }

  .vafFormContent {
    height: 60vh;
  }

  .vafButtonContainer {
    flex-direction: row;
    gap: 50px;
  }

  .vafFormHeaderImg {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .vafForm {
    width: 80vw;
    margin: 16px;
  }

  .vafFormContent {
    height: 60vh;
  }

  .vafButtonContainer {
    flex-direction: row;
    gap: 50px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .vafForm {
    width: 60vw;
    margin: 20px;
  }

  .vafFormContent {
    height: 60vh;
  }

  .vafButtonContainer {
    flex-direction: row;
    gap: 100px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .vafForm {
    width: 50vw;
    margin: 22px;
  }

  .vafFormContent {
    height: 60vh;
  }

  .vafButtonContainer {
    flex-direction: row;
    gap: 150px;
  }
}

@media (min-width: 1201px) {
  .vafForm {
    margin: 24px;
  }

  .vafFormContent {
    height: 65vh;
  }

  .vafButtonContainer {
    flex-direction: row;
    gap: 200px;
  }
}