@import "../../../../shared/styles/variables.scss";

.SubscriptionContainer {
  width: 90%;
}
.SubscriptionContent {
  margin: 10px 0;
}
.SubscriptionDetails {
  height: 248px;
  width: 100%;
  left: 428px;
  top: 217px;
  border-radius: 25px;
  padding: 24px;
  background: linear-gradient(208.23deg, #874fff 8.5%, #1c32f3 91.95%);
  color: white;
  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    color: white !important;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
  }
  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
  }
  div:nth-child(2) {
    background-color: white;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.51);
    color: $secondaryBlue;
    width: fit-content;
    padding: 3px 16px;
    border-radius: 50px;
    p {
      margin: 8px 0;
    }
    span {
      font-weight: 600;
    }
  }
}
.PaymentMethodContainer {
  border: 2px solid $secondaryBlue;
  border-radius: 20px;
  padding: 24px;
  color: $secondaryBlue;
  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
  }
}

.CancelSubscriptionContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 350px;
  padding: 48px 24px;
  border: 3px solid #ec5649;
  border-radius: 20px;
  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
  }
  button:nth-child(3) {
    margin: 20px 0px;
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}
.ChangePaymentMethod {
  text-decoration: underline;
  cursor: pointer;
  color: #244bff;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.PaymentChangeContainer {
  padding: 24px;
  min-width: 300px;
  overflow: hidden;
}
.PaymentChangeButtons {
  display: flex;
  flex-direction: column;
  button {
    margin: 10px 0;
  }
}
