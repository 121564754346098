@import '../../styles/variables.scss';

.switchContainer {
  clear: left;
  display: inline-flex;
}

.switchContainer p {
  color: $catalinaBlue;
  font-weight: bold;
  font-size: medium;
  padding: 0.5em;
  padding-right: 0;

  @media (max-width: 460px) {
    margin: 0;
  }
}

.switch {
  position: relative;
  display: flex;
  width: 220px;
  height: 50px;
  margin-top: 10px;
  margin-left: auto;

  @media (max-width: 600px) {
    margin-left: 0;
    width: 100px;
    height: 40px;
    margin-top: 0px;
    align-self: center;
  }
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switchSlider {
  position: absolute;
  cursor: pointer;
  width: 220px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border-radius: 100px;
  box-shadow: inset 0px 2px 4px rgba(73, 102, 207, 0.25);
  -webkit-transition: 0.4s;
  transition: 0.4s;

  @media (max-width: 600px) {
    width: 7rem;
  }
}

.switchSlider:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 60%;
  background-color: $secondaryBlue;
  color: $pureWhite;
  border-radius: 100px;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  @media (max-width: 600px) {
    margin-left: 0;
    width: 50px;
    height: 32px;
  }
}

.switchContainerinput:checked + .switchSlider {
  background-color: $secondaryBlue;
}

.switchContainer input:checked + .switchSlider:before {
  -webkit-transform: translateX(70%);
  -ms-transform: translateX(70%);
  transform: translateX(70%);

  @media (max-width: 600px) {
    -webkit-transform: translateX(54px);
    -ms-transform: translateX(54px);
    transform: translateX(54px);
  }
}

.true {
  color: $pureWhite;
  position: absolute;
  transform: translate(20%, -50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  top: 50%;
  left: 60%;
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 16px;
    left: 55%;
  }
}

.false {
  color: $pureWhite;
  position: absolute;
  -webkit-transition: 0.4s;
  transform: translate(-125%, -50%);
  transition: 0.4s;
  top: 50%;
  font-size: 20px;
  left: 55%;

  @media (max-width: 600px) {
    font-size: 16px;
    left: 48%;
  }
}

.inactiveLeft {
  color: black;
  left: 65%;
  position: absolute;
  top: 25%;
  font-size: 20px;
}

.inactiveRight {
  color: black;
  left: 7%;
  position: absolute;
  top: 22%;
  font-size: 20px;
}

.hide {
  display: none;
}

.fadeIn {
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
