@import '../../../../shared/styles/variables.scss';

.DashboardProfileContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ProfileContent {
  background: white;
  border-radius: 20px;
  width: 100%;
  padding: 24px 12px;
  margin: 20px 0;
}

.PublicProfile {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  button {
    margin: 20px 0 0 0 !important;
  }
}

.PublicProfileContent {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  h2 {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    margin: 0;
    padding: 0;
    flex: 100%;
    width: 100%;
    p {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: white;
      background-color: red;
      border-radius: 20px;
      padding: 3px 12px;
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 0;
    flex: 100%;
  }
  a {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #244bff;
    text-decoration: underline;
    flex: 100%;
    display: flex;
    align-items: center;
    img {
      margin-left: 10px;
    }
  }
}

.ServiceLabel {
  margin: 0 10px 10px 0;
  background-color: white;
  border-radius: 20px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  color: $secondaryBlue;
  width: fit-content;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.51);
}

.CompanyInfo {
  width: 100%;
  padding: 0 24px;
  div {
    p:nth-child(1) {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      color: $secondaryBlue;
      margin: 0;
    }
    p:nth-child(2) {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      margin: 3px 0 12px 0;
    }
  }
}

.LanguagesContainer {
  width: 100%;
  display: flex;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    img {
      width: 24px;
      height: 24px;
      margin: 0 8px;
    }
  }
}

.IndustryExperience {
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    span {
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      color: $secondaryBlue;
    }
  }
}

.PricingContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  button {
    margin: 20px 0 0 0 !important;
  }
}

.Pricing {
  display: flex;
  flex-wrap: wrap;
  background: white;
  border-radius: 20px;
  width: 100%;
  padding: 24px;
  margin: 20px 0;
  h2 {
    width: 100%;
  }
  h3 {
    width: 100%;
    margin: 0 0 6px 0;
    span {
      font-weight: normal;
      font-size: 16px;
      margin-left: 12px;
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    flex: 48%;
    span {
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      color: $secondaryBlue;
    }
  }
}

@media only screen and (max-width: 600px) {
  .DashboardContentContainer {
    width: 100%;
  }
  .PublicProfile {
    flex-direction: column;
  }
}
