@import '../../shared/styles/variables.scss';
.referral .popUp {
  width: 450px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
// .MuiOutlinedInput-root {
//   font-family: 'Satoshi !important';
//   border: 1px solid #000b16 !important;
// }
.MuiInputBase-input.Mui-disabled {
  font-family: 'Satoshi', sans-serif !important;
  font-weight: 600;
}
.MuiOutlinedInput-inputMultiline {
  font-family: 'Satoshi', sans-serif !important;
  font-weight: 600;
}
.refContainer {
  display: 'flex';
  flex-direction: 'column';
  width: 100%;
}
.refTitleContainer {
  display: flex;
}
.refTitle {
  color: $azureBlue !important;
  font-weight: 700;
  width: 100%;
  font-size: 50px;
  line-height: 62.5px;
}
.black {
  color: #000b16;
}
.blue {
  color: #1c32f3;
  text-transform: uppercase;
}
.grey {
  color: #000b16;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
}
.blueHr {
  color: $azureBlue;
  border: 1px solid #1c32f3;
  margin: 32px 0;
}
h2 {
  color: $azureBlue !important;
  font-weight: 700;
  width: 100%;
  font-size: 28px;
  line-height: 40px;
}

p {
  font-weight: 500;
  font-size: 18px;
  span {
    //color: $azureBlue;
    font-weight: 700;
    font-size: 18px;
  }
  a {
    width: fit-content;
  }
}
.refDetailRow {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #333c45;
}
.refDetailText {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #333c45;
}
.refpopbtncont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.refBtnCont {
  display: flex;

  align-items: center;
  justify-content: space-evenly;
}
.bgBlue {
  background-color: #f0f2fe;
  border-radius: 24px;
  width: 100%;
  padding: 40px;
}
.bgDarkBlue {
  background-color: $azureBlue;
  color: white;
  border-radius: 24px;
  width: 100%;
  padding: 40px;
  font-size: 16px;
  line-height: 30px;
}
.refYellowText {
  color: #fdb400;
}
.marginTop2 {
  margin-top: 2rem;
}
.marginBot32 {
  margin-bottom: 32px;
}
.marginBot16 {
  margin-bottom: 16px;
}
.refFlex {
  display: flex;
  gap: 32px;
}
.flexbasis {
  width: 50%;
  max-width: 600px;
}
.announceContainer {
  display: flex;
}
.referralSocial {
  max-width: 90%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}
.refSocialCont {
  display: flex;
  justify-content: space-evenly;
}
.refInputTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #000b16;
  margin-top: 0;
}
.refLastBtn {
  align-self: flex-end;
  margin-top: 32px;
}
.alignRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.tableTitles {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.tableCellfirst {
  width: 30%;
}
.tableCell {
  width: 15%;
}
.errorMsg {
  font-size: 12px;
  line-height: 16px;
  color: crimson;
  font-weight: 700;
}
.deleteX {
  color: $azureBlue;
  margin: 0 20px;
}
.jss1 {
  position: relative !important;
  //border: none !important;
  pointer-events: auto !important;
}
.jss1 fieldset {
  display: none !important;
}

.sticky {
  position: sticky;
  top: 0;
}

@media (max-width: 1300px) {
  .bgDarkBlue {
    padding: 20px;
  }
}
@media (max-width: 600px) {
  .referral {
    .popUp {
      width: 100%;
      padding: 12px;
      line-height: 26px;
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
      span {
        font-size: 16px;
      }
    }
  }
  .refTitle {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
  .detailsPic {
    display: none;
  }
  .bgBlue {
    margin-top: 16px;
    border-radius: 12px;

    padding: 12px;
  }
  .refBtnCont {
    flex-direction: column;
  }
  .refFlex {
    flex-direction: column;
  }
  .flexbasis {
    width: 100%;
  }
  .tableCell,
  .tableCellfirst {
    font-size: 12px;
  }
}
