@import '../../shared/styles/variables.scss';

.EntryContainer {
  min-height: 820px;
  width: 100%;
  position: relative;
}

.EntryFields {
  padding: 70px 70px 70px 100px;
  display: flex;
  flex-direction: column;
  min-height: 820px;
  flex: 3;
}

.EntryHelpBox {
  display: flex;
  flex-direction: column;
  //padding: 30px 70px 70px 70px;
  height: fit-content;
  min-height: fit-content;
  min-width: 400px;
  width: 100%;
  background: linear-gradient(208.23deg, #874fff 8.5%, #1c32f3 91.95%);
  //color: white;
  p {
    margin: 40px 0px 8px 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }
  span {
    font-size: 14px;
    line-height: 24px;
  }
  h2 {
    font-size: 54px;
    font-weight: 600;
  }
}
.LoginBlueBox {
  display: flex;
  flex-direction: column;
  //padding: 30px 70px 70px 70px;
  margin-top: 50px;
  height: 450px;
  min-height: 450px;
  min-width: 400px;
  width: 100%;
  background: linear-gradient(208.23deg, #874fff 8.5%, #1c32f3 91.95%);
  //color: white;
}
.EntryFieldsBox {
  padding: 20px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 686px;
  width: 482px;
  border-radius: 20px;
  h2 {
    width: 100%;
    color: white !important;
    font-size: 36px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 36px;
  }
  p {
    color: black;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }
}

.regHelpBox {
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: 500px;
  width: 100%;
  background: #ccfff0;
  // border: 2px solid #ccfff0;
  border-radius: 8px;
  p {
    margin: 20px 0px 10px 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }
  span {
    font-size: 14px;
    line-height: 24px;
  }
  h2 {
    font-size: 54px;
    font-weight: 600;
  }
}

.regContainer {
  display: flex;
  align-items: center;
  gap: 40px;
}

.regItem {
  width: 50%;
}

.regPaper {
  width: 100% !important;
  height: 500px !important;
}

.regText {
  margin: 30px 0;
  min-width: 410px;
  height: 200px;
  margin: 0;
}

.loginPaper {
  position: absolute;
  top: 160px;
  left: 0px;
}
.loginTitle {
  margin-bottom: '54px';
  font-size: '36px';
  color: 'white';
}

.EntrySeperator {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid $strongBlue;
  line-height: 0.1em !important;
  margin: 41px 0 41px;
  span {
    color: black !important;
    font-weight: normal;
    background: #fff;
    padding: 0 10px;
  }
}

.TutorialVideoBtn {
  margin-top: auto !important;
  background: white !important;
  width: 195px !important;
  height: 65px !important;
  border-radius: 20px !important;
  span {
    color: $strongBlue !important;
    text-transform: none !important;
  }
}

.FacebookEntryBtn {
  margin-bottom: 24px !important;
  position: relative !important;
  height: 48px !important;
  width: 100% !important;
  background: $babyBlue !important;
  span {
    color: white !important;
    text-transform: none !important;
  }
  img {
    position: absolute;
    left: 10%;
  }
}
.FacebookEntryBtn:hover {
  background: #4da1f0 !important;
}

.GoogleEntryBtn {
  position: relative !important;
  height: 48px !important;
  width: 100% !important;
  background: white !important;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168) !important;
  span {
    color: $strongBlue !important;
    text-transform: none !important;
  }
  img {
    position: absolute;
    left: 10%;
  }
}
.GoogleEntryBtn:hover,
.TutorialVideoBtn:hover {
  background: rgb(250, 250, 250) !important;
}

.figureContainer {
  position: absolute;
  top: 120px;
  right: 100px;
}
.miscContainer {
  position: absolute;
  top: 160px;
  right: 100px;
}
@media (max-width: 600px) {
  .EntryHelpBox {
    display: none;
  }
  .EntryHelp {
    display: none;
  }
  .EntryContainer {
    padding: 0;
  }
  .EntryFieldsBox {
    padding: 0;
    width: 100%;
  }
  .EntryFields {
    align-items: center;
    padding: 0;
  }
  .miscContainer {
    display: none;
  }
  .figureContainer {
    display: none;
  }
  .loginPaper {
    top: 0;
    left: 0;
  }
  .regItem {
    width: 100%;
    margin: 0px;
  }
  .regContainer {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
  .regText {
    text-align: center;
    min-width: auto;
    height: auto;
  }
  .regItemImg {
    display: none;
  }
  .LoginBlueBox {
    margin-top: 0px;
    // height: 450px;
    min-height: 30vh;
    min-width: 100%;
    width: 100%;
    background: linear-gradient(208.23deg, #874fff 8.5%, #1c32f3 91.95%);
    //color: white;
  }
  .loginTitle {
    margin-bottom: '54px';
    margin-left: 10px;
    margin-top: 16px;
    font-size: '36px';
    color: 'white';
  }
  .EntryContainer {
    height: 100%;
    width: 100%;
    position: relative;
  }
}
@media (max-width: 930px) {
  .miscContainer {
    display: none;
  }
}
