* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  padding: 0;
}
.App {
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Satoshi', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
  font-family: 'Satoshi', sans-serif;
  color: #000b64 !important;
}
p {
  font-family: 'Satoshi', sans-serif !important;
  font-weight: 500;
}
button > span {
  font-family: 'Satoshi', sans-serif !important;
  font-weight: 600;
  color: #53566f;
}
input {
  font-family: 'Satoshi', sans-serif !important;
  font-weight: 700 !important;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}
/* input:hover {
  border: 2px solid #000b64;
}
input:focus {
  border: 2px solid #fdb400;
} */
.jss1 {
  position: relative !important;
  /* border: none !important; */
}
.todo-list {
  display: grid;
  grid-template-columns: 20rem 20rem 20rem;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.App {
  display: flex;
  flex-direction: column;
}
#content-container {
  flex: 1;
  display: flex;
  min-height: 900px;
  min-width: 100%;
}
@media (max-width: 1440px) {
  #content-container {
    min-width: 100%;
  }
}
@media (min-width: 600px) {
  .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 850px) {
  .todo-list {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  #content-container {
    min-height: 900px;
    min-width: 300px;
    width: 100vw;
  }
}

/*SCROLLBAR

/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(172, 172, 172);
}
