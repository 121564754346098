@import '../../../../shared/styles/variables.scss';

.DashboardFiltersContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.FiltersContent {
  background: white;
  border-radius: 20px;
  width: 100%;
  padding: 24px 12px;
  margin: 20px 0;
}

.FiltersContentContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 24px;
  h2 {
    width: 100%;
  }
  button {
    margin: 20px 0 0 0 !important;
  }
}

.ServicesContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.ServicesCheckBox {
  flex-basis: 30%;
}
.ExtraInfoCheckBox {
  flex-basis: 100%;
}

.ServiceLabelFilters {
  text-align: center;
  margin: 0 10px 10px 0;
  background-color: white;
  border-radius: 20px;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  color: $secondaryBlue;
  flex-basis: 20% !important;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.51);
}

.percentagesign {
  font-size: 1.1em !important;
  display: flex;
  align-items: center;
}
