.regFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.regLabel {
  margin: 3px 0px 0px 3px;
  display: block;
  font-weight: 500;
  font-size: 0.9rem;
}

.regForm {
  width: 100%;
  max-width: 580px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  margin: 24px;
  box-shadow: 0px 10px 20px 0px rgba(11, 15, 49, 0.1);
  position: relative;
  justify-content: center;
  border-radius: 16px 16px 16px 16px;
}

.regFormContentHeader {
  position: relative;
  width: 100%;
  min-height: 120px;
  padding: 24px;
  border-radius: 16px 16px 0px 0px;
  background: linear-gradient(85.22deg, #0b1fcb 0%, #9747ff 92.28%);
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 2px solid #cccccc;
  border-bottom: none;
}

.regTitle {
  font-size: 1.2rem;
  text-align: center;
  color: white;
}

.regFormHeaderImg {
  width: 100px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.regFormMainLabel {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 12px 0px 6px 0px;
}

.regFormContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 580px;
  height: 90vh;
  gap: 6px;
  background-color: #ffffff;
  overflow: auto;
  margin: 0 auto;
  padding: 4px 12px;
  padding-bottom: 20px;
  position: relative;
}

.regButtonContainer {
  position: relative;
  background-color: white;
  width: 100%;
  min-height: 60px;
  max-height: 100px;
  padding: 12px;
  border-radius: 0px 0px 16px 16px;
  display: flex;
  justify-content: space-between;
  gap: 100px;
  border-top: none;
  z-index: 1;
}

.regSuccessMessage {
  position: relative;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}

.regTextArea {
  width: 100%;
  align-self: center;

  .MuiInputBase-input {
    font-family: "Satoshi", sans-serif;
    color: black;
    font-size: 0.9rem;
  }
}

.regTextField {
  width: 100%;
  align-self: center;

  .MuiInputBase-input {
    font-size: 0.9rem;
    font-weight: 400 !important;
  }
}

.regSelectMenu {
  width: 100%;
  align-self: center;
  font-family: "Satoshi", sans-serif;
  color: black;

  .MuiInputBase-input {
    font-size: 0.9rem;
  }
}

.regCheckboxContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
}

.regFormControlLabel {
  width: 100%;
  margin: 2px 0;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: black;
}

.regFormControlLabelChecked {
  width: 100%;
  margin: 2px 0;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  background-color: #e3f2fd;
  border-radius: 4px;
  border: 1px solid #1c32f3;
  color: #1c32f3;
}

.regCheckbox {
  padding: 0;
  margin: 0;
}

.regFormBtnMaxWidth {
  max-width: 110px;
}

.regFormClosed {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  text-align: center;
}

.regFormOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px 16px 16px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  color: white;
  text-align: center;
}

.regFormOverlayMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  z-index: 6;
}

.regNoteContainer {
  position: sticky;
  bottom: 0px;
  width: 100%;
  max-width: 580px;
  background-color: #ffffff;
  border-top: 1px solid #ccc;
  padding: 6px 12px;
  z-index: 1;
}

@media (max-width: 480px) {
  .regForm {
    margin: 0;
    width: 100vw;
  }

  .regFormContent {
    height: calc(100vh - 120px);
  }
}

.regButtonContainer {
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.regFormHeaderImg {
  display: none;
}


@media (max-width: 480px) {
  .regForm {
    width: 100vw;
  }

  .regFormContent {
    height: 50vh;
  }

  .regButtonContainer {
    flex-direction: row;
    gap: 50px;
  }

  .regFormContentHeader {
    border-radius: 0px;
  }

  .regFormHeaderImg {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .regForm {
    width: 80vw;
    margin: 16px;
  }

  .regFormContent {
    height: 55vh;
  }

  .regButtonContainer {
    flex-direction: row;
    gap: 50px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .regForm {
    width: 60vw;
    margin: 20px;
  }

  .regFormContent {
    height: 55vh;
  }

  .regButtonContainer {
    flex-direction: row;
    gap: 100px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .regForm {
    width: 50vw;
    margin: 22px;
  }

  .regFormContent {
    height: 55vh;
  }

  .regButtonContainer {
    flex-direction: row;
    gap: 150px;
  }
}

@media (min-width: 1201px) {
  .regForm {
    margin: 24px;
  }

  .regFormContent {
    height: 55vh;
  }

  .regButtonContainer {
    flex-direction: row;
    gap: 200px;
  }
}