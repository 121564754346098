@import '../../../../shared/styles/variables.scss';

.ReqMessagePaper {
  padding: 0;
  max-width: 420px !important;
  border: none;
}

.ReqMessageText {
  padding: 0;
  h2 {
    width: 100%;
    font-family: 'Satoshi';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #505581 !important;
  }
  p {
    color: $azureBlue !important;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 16px 0;
  }
}

.ReqMessageHeader {
  display: flex;
  align-items: center;
  position: relative;
  height: 52px;
  background-color: #fafafa;
  padding: 14px 64px;
  p {
    margin: 0;
    font-family: 'Satoshi';
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0b0f31;
  }
}

.sendrfp {
  position: absolute;
  top: 0;
  left: 0;
}

.react-tel-input .special-label {
  display: inline !important;
  font-family: 'Satoshi', sans-serif !important;
}

.ReqMessageLength {
  display: flex;
  justify-content: space-between;
  span {
    font-family: 'Satoshi';
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;

    color: #0b0f31;
    font-size: 14px;
  }
}

.ReqMsgInfoCont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  p {
    font-weight: 400;
    line-height: 24px;
    font-size: 18px;
    margin: 8px 0 8px 3px;
    width: 100%;
  }
}

.ReqMsgSend {
  background-color: #fdb400 !important;
  border-radius: 6px !important;
  width: 97px;
  height: 44px;
  margin-right: 16px;
  span {
    color: #0b0f31 !important;
    text-transform: none;
    padding: 5px;
  }
}

.ContactInfoInputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ContactNameInput {
  width: 100%;
  margin-bottom: 12px;
}

.css-ahj2mt-MuiTypography-root {
  font-family: 'Satoshi', sans-serif !important;
}

.marginBot12 {
  margin-bottom: 12px;
}

.reqRFPButtonCustom {
  width: 40% !important;
}

@media (max-width: 600px) {
  .ReqMessageText {
    padding: 0;
    h2 {
      width: 100%;
      color: $babyBlue;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    }
    p {
      color: $babyBlue;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin: 0 0 16px 0;
    }
  }
  .ReqMsgInfoCont {
    p {
      line-height: 18px;
      font-size: 12px;
      margin-top: 8px;
    }
  }
  .ReqMessageLength {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    span {
      color: #0b0f31;
      font-family: 'Satoshi';
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .ReqMessagePaper {
    height: 100vh !important;
    width: 100vw !important;
  }

  .ContactInfoContainer {
    padding: 0;
  }

  .ContactInfoInputs {
    flex-direction: column;
  }

  .ContactNameInput {
    width: 100%;
    margin-bottom: 8px;
  }
}
