//  COLORS
$azureGradient: -webkit-gradient(
  linear,
  left top,
  left bottom,
  from(#21528b),
  to(#093c77)
);

$sprayGradient: -webkit-gradient(
  linear,
  left top,
  left bottom,
  from(#1e4e86),
  to(#21528b)
);

$blueGradient: -webkit-gradient(
  linear,
  left top,
  left bottom,
  from(#81d2f0),
  to(#52afd1)
);

$reverseblueGradient: -webkit-gradient(
  linear,
  left top,
  left bottom,
  from(#52afd1),
  to(#81d2f0)
);

$whiteGradient: -webkit-gradient(
  linear,
  left top,
  left bottom,
  from(white),
  to(#dae3f0)
);
$seaGradient: -webkit-gradient(
  linear,
  left top,
  left bottom,
  from(#016394),
  to(#0b80a1)
);
$reverseseaGradient: -webkit-gradient(
  linear,
  left top,
  left bottom,
  from(#0b80a1),
  to(#016394)
);

$pureWhite: white;
$lightBlue: #b6eeff;
$sprayBlue: #81d2f0;
$azureBlue: #1c32f3;
$castilWhite: #f3f6fa;
$greenHaze: #009b60;
$webOrange: #ffa800;
$webYellow: #ffbe17;
$blumine: #1e4e86;
$catalinaBlue: #093c77;
$darkblumine: #21528b;
$lightGrey: #bfc2c1;
$darkGrey: #919191;
$babyBlue: #3797f0;
$strongBlue: #1a00bd;
$secondaryBlue: #2d46b9;
$darkNavy: #000b64;
$royalBlue: #1c32f3;

$mercuryGradient: -webkit-gradient(
  linear,
  left top,
  left bottom,
  from(#ffffff),
  to(#e8e8e8)
);
$whiteSmoke: #f5f5f5;

// Size
$border-radius: 4px;

// Responsive
$medium-screen: 768px;
$large-screen: 992px;
$extra-large-screen: 1200px;
