.dlpProviderProfile {
	display: flex;
	flex-direction: column;
	// height: 100%;
	width: 100%;
	max-width: 1370px;
	padding: 3% 3%;
}

.dlpProviderBackButton {
	.dlpProviderBackButtonCustom {
		display: flex;
		align-items: center;
		background-color: white;
		color: black;
		border-radius: 8px;
		padding: 8px 20px 8px 0;
		letter-spacing: normal;
		font-size: 0.9rem;
		font-weight: 600;
		text-transform: none;
		box-shadow: none;

		&:hover {
			background-color: rgb(222, 222, 222);
		}

		&:active,
		&:focus {
			background-color: white;
		}
	}
}

.dlpLongArrowLeft {
	padding: 0 12px 0 16px;
}

// .dlpProviderHeader {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     width: 100%;
//     margin: 10px 0;
//     color: white;
//     background: linear-gradient(85.22deg, #0b1fcb 0%, #9747ff 92.28%);
//     border-radius: 18px;
//     padding: 0px 24px;
// }

// .dlpProviderHeaderText {
//     display: flex;
//     flex-direction: column;
// }

// .dlpProviderHeaderTextUp {
//     font-size: 0.8rem;
//     font-weight: 100;
//     padding: 24px 0px 10px 0px;
// }

// .dlpProviderHeaderTextDown {
//     font-size: 1.1rem;
//     padding: 10px 0px 24px 0px;
// }

// .dlpProviderHeaderImages {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }

// .headerLogo {
//     position: relative;
//     bottom: -20px;
//     width: 80px;
// }

// .headerAccountant {
//     position: relative;
//     bottom: -20px;
//     width: 80px;
// }

.dlpProviderHeaderSleek {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 10px 0;
	border-radius: 18px;
}

.dlpProviderHeaderTextSleek {
	display: flex;
	flex-direction: column;
}

.dlpProviderHeaderTextUpSleek {
	font-size: 0.8rem;
	font-weight: 500;
	color: #1c32f3;
	padding: 4px 0px 4px 0px;
}

.dlpProviderHeaderTextDownSleek {
	font-size: 1.1rem;
	font-weight: 600;
	padding: 6px 0px 6px 0px;
}

.dlpDividerHeaderSleek {
	background-color: #e3e5e9;
	height: 1px;
	margin-bottom: 8px;
}

.dlpProviderContent {
	display: flex;
	width: 100%;
	height: 100%;
	margin: 10px 0px;

	.dlpProviderContentLeft {
		width: 25%;
		min-width: 200px;
		border: 1.6px solid #e3e5e9;
		border-radius: 12px;
		box-sizing: border-box;
		padding: 0px 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.dlpProviderMainContainer {
			display: flex;
			flex-direction: column;
			width: 100%;

			.dlpProviderMainContainerLeft {
				display: flex;
				justify-content: center;

				.dlpProfileImageContainer {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 12px 0px;
					width: 120px;

					.dlpProfileImage {
						width: 120px;
						height: 120px;
						border-radius: 50%;
						object-fit: cover;
						// object-position: top;
						position: relative;
					}

					.dlpProfileImageDefault {
						width: 120px;
						position: relative;
					}

					.dlpApproved {
						width: 40px;
						position: absolute;
						top: 0px;
						right: 0px;
					}
				}
			}

			.dlpProviderProfileName {
				font-weight: 600;
				text-align: center;
			}

			.dlpProviderProfileAvgContainer {
				display: 'flex';
				justify-content: center;
				align-items: center;
				margin-top: 8px;

				.dlpProviderProfileAvgStars {
					margin: 4px 0px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.dlpProviderProfileAvgNumber {
					color: #505581;
					font-size: 0.8rem;
					margin-left: 6px;
				}

				.dlpProviderProfileReviewsNumber {
					color: #505581;
					font-size: 0.8rem;
					margin-left: 6px;
				}
			}

			.dlpRFPButton {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 14px 0px;
				width: 100%;

				.dlpRFPButtonCustom {
					background-color: #fdb400;
					color: black;
					border-radius: 8px;
					padding: 10px 20px;
					font-size: 0.9rem;
					font-weight: 600;
					text-transform: none;
					box-shadow: none;
					width: 100%;
					letter-spacing: normal;

					&:hover {
						background-color: #f5ca61;
					}

					&:active,
					&:focus {
						background-color: #fdb400;
					}
				}
			}

			.dlpNotVerifiedYetConatiner {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 8px;

				.dlpNotVerifiedYet {
					width: 16px;
				}

				.dlpNotVerifiedYetText {
					font-size: 0.8rem;
					margin-left: 6px;
				}
			}

			.dlpLanguagesContainer {
				display: flex;
				gap: 16px;
				margin: 8px 0px;
				flex-wrap: wrap;
				justify-content: center;

				.dlpLangHU {
					width: 32px;
				}

				.dlpLangGB {
					width: 32px;
				}

				.dlpLangDE {
					width: 32px;
				}

				.dlpLangFR {
					width: 32px;
				}

				.dlpLangCN {
					width: 32px;
				}

				.dlpLangRU {
					width: 32px;
				}

				.dlpLangRO {
					width: 32px;
				}

				.dlpLangES {
					width: 32px;
				}
			}
		}

		.DlpDividerContentLeft {
			width: 100%;
			background-color: #e3e5e9;
			height: 1px;
			margin: 14px 0px;
		}

		.dlpAttributesContainer {
			width: 100%;
		}

		.dlpIndustryContainer {
			display: flex;
			align-items: center;
			width: 100%;

			.dlpIndustry {
				width: 30px;
			}

			.dlpIndustryText {
				font-size: 0.9rem;
				font-weight: 600;
				margin-left: 8px;
			}
		}

		.dlpIndustryContainerCards {
			display: flex;
			gap: 8px;
			flex-wrap: wrap;
			margin-top: 12px;

			.dlpIndustryCard {
				border-radius: 4px;
				padding: 6px 6px;
				font-size: 0.9rem;
				font-weight: 500;
				color: #4d5bec;
				text-align: left;
				background-color: #f3f4fe;
			}
		}

		.dlpRatingSumContainer {
			display: flex;
			align-items: center;
			width: 100%;

			.dlpStarGrey {
				width: 30px;
			}

			.dlpRatingSumAvgNumber {
				font-size: 0.9rem;
				font-weight: 600;
				margin-left: 8px;
				color: #4d5bec;
			}

			.dlpRatingSumText {
				font-size: 0.9rem;
				color: #505581;
				margin-left: 4px;
			}
		}

		.dlpPackageSizeContainer {
			display: flex;
			align-items: center;
			width: 100%;
			margin-top: 8px;

			.packageSize {
				width: 30px;
			}

			.dlpPackageSizeText1 {
				font-size: 0.9rem;
				font-weight: 600;
				color: #4d5bec;
				margin-left: 8px;
			}
		}

		.dlpDigBadgeContainer {
			display: flex;
			align-items: center;
			width: 100%;
			margin-top: 8px;

			.dlpDigBadge {
				width: 30px;
			}

			.dlpDigBadgeTextContainer {
				display: flex;
				flex-wrap: wrap;
				margin-left: 8px;
			}

			.dlpDigBadgeText1 {
				font-size: 0.9rem;
				font-weight: 600;
				color: #4d5bec;
				margin-left: 4px;
			}

			.dlpDigBadgeText2 {
				font-size: 0.9rem;
				color: #505581;
				margin-left: 4px;
			}
		}

		.dlpWoComplaintContainer {
			display: flex;
			align-items: center;
			width: 100%;

			.dlpWoComplaint {
				width: 30px;
			}

			.dlpWoComplaintTextContainer {
				display: flex;
				flex-wrap: wrap;
				margin-left: 8px;
			}

			.dlpWoComplaintNumber {
				font-size: 0.9rem;
				font-weight: 600;
				color: #4d5bec;
				margin-left: 4px;
			}

			.dlpWoComplaintText {
				font-size: 0.9rem;
				color: #505581;
				margin-left: 4px;
			}
		}

		.NoOfCustomersContainer {
			display: flex;
			align-items: center;
			width: 100%;
			margin: 8px 0px 20px 0px;

			.dlpNoOfCustomers {
				width: 30px;
			}

			.dlpNoOfCustomersTextContainer {
				display: flex;
				flex-wrap: wrap;
				margin-left: 8px;
			}

			.dlpNoOfCustomersNumber {
				font-size: 0.9rem;
				font-weight: 600;
				color: #4d5bec;
				margin-left: 4px;
			}

			.dlpNoOfCustomersText1 {
				font-size: 0.9rem;
				color: #505581;
				margin-left: 4px;
			}

			.dlpNoOfCustomersText2 {
				font-size: 0.9rem;
				color: #505581;
				margin-left: 4px;
			}
		}
	}
	.dlpIndustryContainer {
		display: flex;
		align-items: center;
		width: 100%;

		.dlpIndustry {
			width: 30px;
		}

		.dlpIndustryText {
			font-size: 0.9rem;
			font-weight: 600;
			margin-left: 8px;
		}
	}

	.dlpIndustryContainerCards {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
		margin-top: 12px;

		.dlpIndustryCard {
			border-radius: 4px;
			padding: 6px 6px;
			font-size: 0.9rem;
			font-weight: 500;
			color: #4d5bec;
			text-align: left;
			background-color: #f3f4fe;
		}
	}

	.dlpProviderContentRight {
		width: 75%;
		margin-left: 24px;
		border-radius: 12px;
		box-sizing: border-box;
		height: 100%;

		.dlpProviderCard {
			padding: 12px 18px;
			margin-bottom: 16px;
			border: 1.6px solid #e3e5e9;
			border-radius: 12px;

			.dlpProviderIntroduction {
				font-weight: 600;

				.dlpHandWave {
					padding-right: 8px;
				}
			}

			.dlpProviderIntroductionText {
				color: #505581;
				padding-top: 12px;
				font-size: 0.9rem;
				line-height: 1.5rem;
			}

			.dlpProviderLiabilityInsurance {
				font-weight: 600;

				.dlpShield {
					padding-right: 8px;
				}
			}

			.dlpProviderLiabilityInsuranceText {
				color: #505581;
				padding-top: 12px;
				font-size: 0.9rem;
				line-height: 1.5rem;
			}
		}

		.dlpProviderCardLast {
			padding: 12px 18px;
			border: 1.6px solid #e3e5e9;
			border-radius: 12px;

			.dlpReviewsPaginator {
				display: flex;
				justify-content: center;
				margin: 16px 0px;
			}

			.dlpProviderReviews {
				font-weight: 600;
				display: flex;
				margin-bottom: 12px;

				.dlpSumOfReviews {
					padding-right: 8px;
					color: #4d5bec;
				}

				.dlpStarBlue {
					padding-right: 8px;
				}
			}

			.dlpProviderReviewsNull {
				font-weight: 600;
				display: flex;

				.dlpSumOfReviews {
					padding-right: 8px;
					color: #4d5bec;
				}

				.dlpStarBlue {
					padding-right: 8px;
				}
			}

			.dlpProviderReviewsContainer {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 12px;
				justify-items: stretch;

				.dlpProviderReviewCard {
					font-size: 0.8rem;
					padding: 10px;
					border: 1.6px solid #e3e5e9;
					border-radius: 12px;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;

					.dlpProviderReviewHeader {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.dlpProviderReviewReviewer {
							font-size: 0.8rem;
							font-weight: 600;
							margin: 4px 0px;
						}

						.dlpProviderReviewDate {
							color: #505581;
							font-size: 0.7rem;
						}
					}

					.dlpProviderReviewAvgContainer {
						margin: 4px 0px;
						display: flex;
						align-items: center;

						.dlpProviderReviewAvgNumber {
							color: #505581;
							font-size: 0.7rem;
							margin-left: 4px;
						}
					}

					.dlpProviderReviewText {
						color: #505581;
					}
				}
			}
		}
	}
}

.dlpShowMoreButton,
.dlpShowLessButton {
	display: none;
}

@media only screen and (max-width: 600px) {
	.dlpProviderProfile {
		display: flex;
		flex-direction: column;

		.dlpProviderBackButton {
			padding: 6px 0px;

			.MuiButtonBase-root {
				padding: 6px 12px 6px 0px;

				&:hover {
					background-color: white;
				}

				&:active,
				&:focus {
					background-color: white;
				}
			}
		}

		.dlpProviderHeaderSleek {
			width: 100%;
			margin: 0px;
			border-radius: 0px;
		}

		.dlpProviderHeaderTextDownSleek {
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;
		}

		.dlpProviderHeaderTextDownSleek span {
			padding: 4px 0px;
		}

		.dlpProviderContent {
			display: flex;
			flex-direction: column;

			.dlpProviderContentLeft {
				width: 100%;
				height: 100%;

				.NoOfCustomersContainer {
					margin: 0px;
				}

				.dlpHiddenContent {
					display: none;
				}

				.dlpShowMoreButton,
				.dlpShowLessButton {
					display: block;
					width: 100%;
					text-align: center;

					button {
						color: #4d5bec;
						font-weight: 300;
						text-decoration: underline;
						letter-spacing: normal;
						padding: 10px 20px;
						font-size: 0.9rem;
						text-transform: none;
						box-shadow: none;
					}
				}

				&.show .dlpHiddenContent {
					display: block;
				}

				&.show .dlpShowMoreButton {
					display: none;
				}

				&.show .dlpShowLessButton {
					display: block;
				}
			}

			.dlpProviderContentRight {
				width: 100%;
				margin: 16px 0px 0px 0px;

				.dlpProviderCardLast {
					margin-bottom: 80px;

					.dlpProviderReviewsContainer {
						display: grid;
						grid-template-columns: repeat(1, 1fr);
						gap: 12px;
						justify-items: stretch;
					}

					.dlpReviewsPaginator {
						display: flex;
						justify-content: center;
						margin: 16px 0px;

						.MuiPaginationItem-root {
							color: transparent;
							background-color: #d2d6fa;
							border-radius: 50%;
							width: 10px;
							height: 10px;
							margin: 0 5px;
							padding: 0;
							min-width: auto;
						}

						.MuiPaginationItem-root.Mui-selected {
							background-color: #4d5bec;
						}

						.MuiPaginationItem-previousNext {
							display: none;
						}
					}
				}
			}

			.dlpProviderMainContainer {
				display: flex;
				flex-direction: row;
				// align-items: center;
				align-items: flex-start;

				// .dlpProviderMainContainerLeft {}

				.dlpProviderMainContainerRight {
					padding: 16px 0px 16px 16px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.dlpProviderProfileName {
						text-align: left;
					}

					.dlpProviderProfileAvgContainer {
						display: flex;
						margin: 0px 0px;
					}

					.dlpLanguagesContainer {
						display: flex;
						justify-content: flex-start;
					}
				}

				.dlpRFPButton {
					position: fixed;
					display: flex;
					bottom: 0px;
					left: 0px;
					padding: 16px;
					width: 100vw;
					background-color: white;
					border-top: 1.6px solid #e3e5e9;
					z-index: 100;

					.dlpRFPButtonCustom {
						&:hover {
							background-color: #fdb400;
						}

						&:active,
						&:focus {
							background-color: #fdb400;
						}
					}
				}
			}
		}
	}
}
