@import '../../../../shared/styles/variables.scss';

.NavigationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 270px;
  padding: 40px 40px 40px 40px;
  background: #111e92;
  div {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 12px 0;
    border-radius: 10px;
    transition: all 500ms ease;
    p {
      color: white;
      font-size: 16px;
      line-height: 21.78px;
      font-weight: 400;
      margin: 6px 0px;
    }
  }
  div:hover {
    padding-left: 16px;
    background: #fff9f0;
    transition: all 500ms ease;
    p {
      color: $secondaryBlue;
    }
  }
  img {
    margin-right: 12px;
  }
}

.Icon16 {
  color: white;
}

.NavigationActive {
  padding-left: 16px;
  background: white;
  p {
    color: $secondaryBlue !important;
    font-weight: 600 !important;
  }
}

.NavigationBorder {
  width: 100%;
  border-bottom: 2px solid $secondaryBlue;
}

.badgeTest {
  margin-top: 50px !important;
}
.Logout {
  p {
    color: white !important;
    font-weight: 600 !important;
  }
}
.Logout:hover {
  background-color: unset !important;
}
