.feedbackForm {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 580px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  box-shadow: 0px 10px 20px 0px rgba(11, 15, 49, 0.1);
  border-radius: 16px 16px 16px 16px;
  // overflow: hidden;
  overflow: auto;
  width: 100vw;
}

.feedbackContentHeader {
  position: relative;
  width: 100%;
  min-height: 120px;
  padding: 34px 24px;
  border-radius: 16px 16px 0px 0px;
  background: linear-gradient(85.22deg, #0b1fcb 0%, #9747ff 92.28%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // border: 1px solid #cccccc;
  border-bottom: none;
}

.feedbackTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  margin-bottom: 0 !important;
  color: white;
  z-index: 1;
}

.feedbackSubTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 0.75);

  z-index: 1;
}

.feedbackFormHeaderImg {
  width: 100px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 0;
}

.feedbackQuestionContainer {
  // border-left: 1px solid #ccc;
  // border-right: 1px solid #ccc;
  padding: 16px;
  font-weight: 500;
}

.feedbackFormContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 580px;
  // height: 70vh;
  height: calc(100vh - 280px);
  gap: 6px;
  background-color: #ffffff;
  // border: 1px solid #ccc;
  // border-radius: 16px;
  overflow: auto;
  margin: 0 auto;
  padding: 16px 12px 16px 12px;
  position: relative;
  padding-bottom: 20vh;
  z-index: 1;
}

.feedbackRadioOptionContainer {
  border: 2px solid #e3e5e9;
  border-radius: 8px;
  padding: 4px 12px;
  margin: 4px 0;
  cursor: pointer;
}

.feedbackFullWidthClickable {
  width: 100%;
  display: flex;
  align-items: center;
}

.feedbackButtonContainer {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  width: 100%;
  background-color: white;
  // min-height: 60px;
  // max-height: 100px;
  padding: 12px;
  border-radius: 0px 0px 16px 16px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  // border: 1px solid #cccccc;
  // border-top: none;
  padding: 16px;
  z-index: 1000;
}

.feedbackFinalPage {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #ffffff;
  overflow-y: auto;
  height: 90vh;
}

.feedbackErrorMessage {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: red;
  font-size: 0.9rem;
}

.feedbackSuccess {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 24px;
  font-size: 1.4rem;
  color: #0b1fcb;
  background-color: #f6f7fe;
  // border: 1px solid #ccc;
  border-radius: 16px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  &::before {
    font-size: 3rem;
    color: #0b1fcb;
    position: absolute;
    top: 16px;
    left: 16px;
    transform: none;
  }

  .thankYouFeedback {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .feedbackForm {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .feedbackContentHeader {
    border-radius: 0px 0px 16px 16px;
  }

  .feedbackFormHeaderImg {
    display: none;
  }

  .feedbackButtonContainer {
    position: fixed;
    bottom: 0;
    border-radius: 0px;
    width: 100vw;
    background-color: white;
  }
}

@media (max-width: 480px) {
  .feedbackFinalPage {
    height: 100%;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .feedbackFormContent {
    height: 55vh;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .feedbackFormContent {
    height: 55vh;
  }

  .feedbackButtonContainer {
    flex-direction: row;
    gap: 100px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .feedbackFormContent {
    height: 55vh;
  }

  .feedbackButtonContainer {
    flex-direction: row;
    gap: 150px;
  }
}

@media (min-width: 1201px) {
  .feedbackFormContent {
    height: 55vh;
  }

  .feedbackButtonContainer {
    flex-direction: row;
    gap: 200px;
  }
}